import {  CircularProgress } from '@material-ui/core';
import React from 'react';
// import { LinearProgress } from '@material-ui/core';
// import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from "components/CustomButtons/Button.js";
// import TestModelDropdown from './../TestModelDropdown';
// import ModelSelectDropdown from './ModelSelectDropdown';
import  ProjectService  from '../../../services/project.service';



function ModelTrainingMetrics({
    model,
    url,
    handleOpenResumeTrainingDialog,
    model_id
    // selected_model,
    // setSelectedModel
  }) {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const domain = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/":"http://127.0.0.1:8000/";
  const handleOpenTestDropdown = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleCloseTestDropdown = () => {
      setAnchorEl(null);
  };
  const handleClickDownload = () => {
      ProjectService.downloadModel({model_id}).then(res => {

          // const url = window.URL.createObjectURL(new Blob([res.data],{type:'application/zip'}));
          const link = document.createElement('a');
          // const domain = "http://localhost:8000/"
          link.href = domain+res.data.file_path;
          // link.href = url;
          // link.setAttribute('download', 'file.zip');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      }).catch(err => {
          console.log(err)
      })
  }
  return <div className='bg-white p-4 mt-4 rounded shadow-sm'>
    <span className='h6'>Training Status</span>
    <div className='d-flex flex-column mt-3'>
        {/* { model?.status ==="completed" && <div className='mb-3'>
            <ModelSelectDropdown data = {model.model_history} selected_model={selected_model} setSelectedModel={setSelectedModel} />
          </div>
        } */}
        <div className='w-100 d-flex align-items-center'>
          {
            ( model?.status !=="completed" && model?.status!=="untrained") && <CircularProgress /> 
          }
          {/* <CircularProgressWithLabel value={20} /> */}
          <span className='mx-3'>
              { model?.status==='training'?"Model training is in progress"
                : 
                model?.status==="completed"?
                  "Model training is completed"
                    :
                  model?.status==="untrained" && model?.training_attempted===true ?"Model training failed":"Model is untrained"}</span>
            {
              model?.status==="untrained" && model?.training_attempted===true ?
                <Button 
                  variant='contained'
                  style= {{'backgroundColor':'#6e6efe'}}  
                  onClick={handleOpenResumeTrainingDialog}
                >
                    Resume Training
                </Button>
                :null
            }      
          { (model?.status==='training' || model?.status==="completed" || (model?.status==="untrained" && model?.training_attempted===true))  && <> 
              <Button
                component={Link}
                to={`${url}/metrics`} 
                variant='contained'
                style={{'backgroundColor':'#6e6efe'}}
                className = "ms-3"
              >
                View Model Metrics
              </Button>
              </>
          } 
          
          {
            (model.status==="completed" ) && <div>
              <Button
                
                onClick={handleOpenTestDropdown} 
                component={Link}
                style={{'backgroundColor':'#6e6efe'}} 
                to={{pathname:`${url}/test`,proj_name:model.proj_name}} 
                className="ms-3"
                
              >
                Test Model
              </Button>
            </div>
          }
          { model?.status==="untrained" && model?.training_attempted===true ?
              <div>
                <span className='ms-4'>OR</span>
                <span
                  style={{'color':'#6e6efe'}}
                  className="model_training_metrics__donwnload_model_link"
                  onClick={handleClickDownload}
                >
                  Downlaod Model
                </span>
              </div>
                :
              null
          }
        </div>
    </div>
  </div>;
}

export default ModelTrainingMetrics;
