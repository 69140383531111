import React from "react";
import ReactDOM from "react-dom";
import App from './App';

import { Provider } from 'react-redux';
import "assets/scss/material-kit-react.scss?v=1.10.0";
import store from "./store";
import { createGenerateClassName,StylesProvider } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  disableGlobal: true, // won't minify CSS classnames when true
  productionPrefix: 'c', // 'jss' by default
});

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider generateClassName={ generateClassName }>
      <App />
    </StylesProvider>
  </Provider>,
  document.getElementById("root")
);
