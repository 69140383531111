import React,{ useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate,useNavigate } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

import { login } from "../../actions/auth";
import { clearMessage } from "actions/message";


const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const history = useNavigate();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const classes = useStyles();
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  useEffect(()=>{
    setErrors({...errors, message });

  },[message])
  useEffect(()=>{
    return ()=>{
      dispatch(clearMessage());
    }
  },[])
  const dispatch = useDispatch();
  const { ...rest } = props;

  const onChangeEmail = (e) => {
    // console.log(e.target.value)
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    // console.log(username)
    dispatch(login({email, password}))
      .then(() => {
        // console.log("worked")
        history("/");
        window.location.reload();
      }).catch(err => {
          setLoading(false);
      });
      
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
  
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer style={{'justifyContent':'center'}} justifyContent="center">
            <GridItem xs={12} sm={12} md={5/12*12}>
              <Card >
                <form className={classes.form} onSubmit={handleLogin}>
                  <CardHeader style={{backgroundColor:'#556eff',color:'white'}} className={classes.cardHeader}>
                    <h2>Login</h2>
                    
                  </CardHeader>
                 
                  <CardBody className="d-flex flex-column">
                    <div>
                      {errors?.message?.non_field_errors?.length>0 && <p className="p-3 rounded text-white bg-danger bg-opacity-75 shadow">{errors?.message?.non_field_errors[0]}</p>}
                      {errors?.message?.detail?.length>0 && <p className="p-3 rounded text-white bg-danger bg-opacity-75 shadow">{errors?.message?.detail}</p>}

                    </div>
                    <input id="email" type="email" value={email} onChange={onChangeEmail} placeholder="Email Address" className="input" />
                  
                    <input id="password" type="password" value={password} onChange={onChangePassword} placeholder="Password" className="input" />
                  </CardBody>
                  <CardFooter className={classes.cardFooter + "d-flex flex-column"}>
                    <Button
                      disabled = {email==="" || password==="" || loading ? true : false }
                      type="submit"
                      variant="contained" 
                      style={{'backgroundColor':'#6e6efe'}}
                    >
                      { loading?"Logging in...":"Login" }
                    </Button>
                    <div className="mt-3">
                      <p className="text-center">
                        <Link to="/forget-password">Forget Password? </Link>
                      </p>
                      <p className="text-center">
                        Don't have an account? <Link to="/register">Register</Link>
                      </p>
                    </div>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
