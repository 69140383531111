import React, { useState,useEffect } from 'react';
import { Link, Route, Routes, useMatch  } from 'react-router-dom';
import ModelProgress from './ModelMetrics/ModelProgress';
import ConfusionMatrix from './ModelMetrics/ConfusionMatrix';
import OCR from './ModelMetrics/OCR';

const ModelMetrics = () => {
    const { path, url } = useMatch ();

    const [selected, setSelected] = useState('progress');
    useEffect(() => {
        document.getElementById(selected).classList.add('text-primary')
        document.getElementById(selected).classList.add('fw-bold')
    }, []);
    const handleClick = (e) =>{
        document.getElementById(selected).classList.remove('text-primary')
        document.getElementById(selected).classList.remove('fw-bold')
        document.getElementById(e.target.id).classList.add('text-primary')
        document.getElementById(e.target.id).classList.add('fw-bold')
        setSelected(e.target.id)
    } 
    return(
        <div style={{'marginTop':'2cm'}} className='py-3 px-5'>
            <div className='bg-white rounded shadow-sm m-5 p-3'>
                <span className='h6'>Model Metrics</span>
                <div className='d-flex row p-4'>
                    <div className='d-flex flex-column lh-lg col-2'>
                        <Link id="progress" to={`${url}`} onClick={handleClick} style={{'color':'black'}} >Progress</Link>
                        <Link id="confusion" to={`${url}/confusion`} onClick={handleClick} style={{'color':'black'}} >Confusion Matrix</Link>
                        <Link  id="ocr" to={`${url}/ocr`} onClick={handleClick} style={{'color':'black'}} >OCR</Link>
                    </div>
                    <div className='col-10'>
                        <Routes>
                            <Route exact path={path} component={ModelProgress}></Route>
                            <Route path={`${path}/confusion`} component={ConfusionMatrix}></Route>
                            <Route path={`${path}/ocr`} component={OCR}></Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelMetrics;
