import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import  Button  from 'components/CustomButtons/Button.js';
import  ProjectService  from '../../services/project.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControl, FormHelperText, FormLabel, Input } from '@material-ui/core';
import { toast } from 'react-toastify';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UploadModelDialog({openModelUploadDialog, handleCloseModelUploadDialog,setIsModelCreated}) {

  const {proj_id} = useParams();

  const [modelFile ,setModelFile] = useState(null);
  const [model_name,setModelName] = useState('');
  const [labels, setLabels] = useState("")
  const [uploading, setUploading ] = useState(false);
  const [showError,setShowError] = useState(false)
  const [isValidName, setIsValidName] = useState(null);

  console.log(proj_id)

  const handleFileChange = (e) =>{
    setModelFile(e.target.files[0]);
  }

  const onChangeLabel = (e) => {
    // console.log(e.target.value)
    const labels = e.target.value;
    setLabels(labels);
  };

  const onChangeModelName = (e) => {
    // console.log(e.target.value)
    setShowError(false)
    const modelName = e.target.value;
    setModelName(modelName);
  };

  const handleUpload = async () => {
    setUploading(true);

    var labs=labels.split(',')
    var labls= labs.map(function (el) {
      return el.trim();
    })

    const formData = new FormData();
    formData.append('file', modelFile);
    formData.set('proj_id',proj_id);
    formData.set('model_name',model_name);
    formData.set('labels',JSON.stringify(labls))
    // const res = await ProjectService.uploadPreTrainedModel(formData).then(res=>{
    //   setUploading(false);
    //   console.log(res);
    //   handleCloseModelUploadDialog();
    //   setIsModelCreated(true);
    // }).catch(error=>{
    //     setUploading(false);
    //     setShowError(true)
    //     setIsValidName(false);
    //     console.log(error)
    // })
    const promise = toast.promise(
      ProjectService.uploadPreTrainedModel(formData),
      {
        'pending':"Uploading",
        'success':'Pre Trained Model uploaded',
        'error':'Model Uploading failed'
      },

    )
    promise.then(res=>{
        setUploading(false);
        console.log(res);
        handleCloseModelUploadDialog();
        setIsModelCreated(true);
      }).catch(error=>{
          setUploading(false);
          setShowError(true)
          setIsValidName(false);
          console.log(error)
      })
  }
  
  return (
    <div>
      <Dialog 
        // onClose={handleCloseModelUploadDialog} 
        aria-labelledby="customized-dialog-title" 
        open={openModelUploadDialog}
        className='w-100'
      >
        <DialogTitle 
          id="customized-dialog-title" 
          onClose={handleCloseModelUploadDialog}
        >
          <span className='h6 text-capitalize' >Upload a Pre-trained Model</span>
        </DialogTitle>
        <DialogContent dividers >
          {
            uploading?<div style={{minWidth:'40vw',minHeight:'20vh'}} className='d-flex justify-content-center align-items-center'> 
                <CircularProgress/> 
                <span className='mx-2'>Uploading...</span>
              </div>: <form style={{minWidth:'40vw',minHeight:'20vh'}} className='d-flex flex-column justify-content-center align-items-center'>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
              >
                  <FormLabel className='text-dark'>Model name</FormLabel>
                  <Box
                      placeholder="Enter model name"
                      name="model_name"
                      value={model_name}
                      onChange={onChangeModelName}
                      component={Input}
                      autoComplete="off"
                      type="text"
                  />
                  <FormHelperText >
                    {   showError?isValidName?<span className='text-success'>
                          *Name Available
                        </span>:
                        <span className='text-danger'>
                          *Model with this name already exists</span>
                          :null
                    }
                  </FormHelperText>
              </FormControl>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
              >
                  <FormLabel className='text-dark'>Labels</FormLabel>
                  <Box
                      className=""
                      value={labels}  
                      placeholder="Enter labels (comma separated)"
                      name="labels"
                      onChange ={ (e) =>onChangeLabel(e)}
                      component={Input}
                      autoComplete="off"
                      type="text"
                  />
              </FormControl>
              <FormControl
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1rem!important"
              >
                <FormLabel className='text-dark'>Model File</FormLabel>
                <input 
                  onChange={handleFileChange} 
                  type="file" 
                  name="file" 
                  id="file" 
                  className='form-control mt-3' 
                />
              </FormControl>
            </form>
          }
         
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus
            disabled={modelFile===null || model_name==="" || labels==="" || (showError && !isValidName)} 
            onClick={(e)=> {
              handleUpload()  
            }
            }
            style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
