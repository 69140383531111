import { Box, FilledInput, FormControl, FormHelperText, FormLabel, Input, TextField, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import {useNavigate} from 'react-router-dom';
import Header from "components/Header/Header.js";
import HeaderLinks from 'components/Header/HeaderLinks';
import Button  from 'components/CustomButtons/Button.js';
import { useState } from 'react';
import ProjectService from "../../services/project.service"
import { toast } from 'react-toastify';

function CreateNewProject(props) {
    const history = useNavigate();

    const { ...rest } = props;
    const [proj_name, setProjName] = useState("");
    const [about,setAbout] = useState("");
    const [showError,setShowError] = useState(false)
    const [isValidName, setIsValidName] = useState(null)

    // useEffect(() => {
    //     ProjectService.validateName('project',{proj_name}).then(res => {
    //         setIsValidName(true);
    //     }).catch(error => {
    //         setIsValidName(false);
    //     })
    // }, [proj_name])

    const handleNameChange = (e) =>{
        setShowError(false)
        setProjName(e.target.value)
    }
    const handleAboutChange=(e)=>{
        setAbout(e.target.value)
    }

    const handleCreate = ()=>{
        const promise = toast.promise(
                            ProjectService.createProject({proj_name,about}),
                            {
                                'pending':"Creating new project...",
                                'success':'Project created successfully',
                                'error':'Error while creating project'
                            },
                            {
                                autoClose: 15000
                            }


                        )
        
        promise.then(res=>{
            console.log(res.data)
            const id = res.data.id
            history(`/project/${id}`);
          }).catch(error => {
            console.log(error)
            setShowError(true)
            setIsValidName(false);
          })
    }
    return (
        <div className=''>
            
            <div style={{marginTop:"2cm"}} className="pt-4 px-5 mx-5">
                <div className='mx-5' >
                    <h4><b>Create new project</b></h4>
                </div>
                <div className='bg-white mx-5 p-5 rounded shadow'>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                    >
                        <FormLabel>Project name</FormLabel>
                        <Box
                            className="mx-3"  
                            placeholder="Enter project name"
                            name="proj_name"
                            onChange={handleNameChange}
                            component={Input}
                            autoComplete="off"
                            type="text"
                        />
                        <FormHelperText >{showError?isValidName?<span className='text-success'>*Name Available</span>:<span className='text-danger'>*Project with this name already exists</span>:null}</FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                    >
                    <FormLabel>About</FormLabel>
                        <Box 
                        className="mx-3"
                        name="about"
                        placeholder='Enter project description'
                        required={true}
                        component={Input}
                        onChange={handleAboutChange}
                        autoComplete="off"
                        type="text"
                        />
                    </FormControl>
                    <div className='d-flex justify-content-center mt-3'>
                        <Button 
                            disabled={(proj_name==="" || about==="" || (showError && !isValidName))? true:false } 
                            onClick={handleCreate} 
                            className="m-4" 
                            variant="contained"
                            style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewProject
