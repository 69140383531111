import { Button } from '@material-ui/core';
import React from 'react';
import { LinearProgress } from '@material-ui/core';
import CircularProgressWithLabel from 'components/CircularProgress/CircularProgressWithLabel';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Button as MaterialButton } from '@material-ui/core';



function TrainedModels({trained_models,url}) {
    const [loading,setLoading] = useState(true);

    const trained_models_array = JSON.parse(trained_models)
    useEffect(()=>{
        if(trained_models_array?.length>0){
            setLoading(false);
        }
    },[])
    return <div className='bg-white p-4 mt-4 rounded shadow-sm'>
        <span className='h6'>Trained Models</span>
        <div className='mt-3'>
            {
                loading===true && trained_models_array?.length===0?<div className='d-flex justify-content-center'>No trained model found</div>
                    :
                trained_models_array?.map((t_model,index) => <div key={index} className='d-flex w-75 justify-content-between p-2 border-1 border-bottom rounded'>
                <span>{t_model}</span>
                <MaterialButton 
                    component={Link} 
                    to={`${url}/test/${t_model}`} 
                    className="ms-3 model-details-page-test-button"
                    variant='contained'
                >
                    Test Model
                </MaterialButton>
            </div>)}
        </div>
    </div>;
}

export default TrainedModels;
