import React, {useState, useEffect,useLayoutEffect} from "react";
// nodejs library that concatenates classes
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import  LinearProgress  from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import DeleteIcon from '@material-ui/icons/Delete';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import { CanvasTools } from "vott-ct";
import ReactImageAnnotate from "react-image-annotate";
import profile from "assets/img/faces/christian.jpg";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ProjectService from "../../services/project.service"
import NotFoundPage from "views/NotFoundPage/NotFoundPage";
import DeleteImageModal from "./DeleteImageModal";
import { useParams } from 'react-router-dom';
import TrainingOptionsDialog from './TrainingOptionsDialogLabel';
import ImagePreview from "./ImagePreview";

const useStyles = makeStyles(styles);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" margin={1}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


export default function LabellingPage(props) {
  const classes = useStyles();

  const scrl = React.useRef(null);


  const [isLoading,setLoading] = useState(true);
  const [isValidModel,setValidModel] = useState(false);
  const [images, setImages] = useState( [] )
  const [labels, setLabels] = useState([])
  const [selectedImage, setSelectedImage] = useState(0)
  const [saved, setSaved] = useState(false);
  const [annotatedImages, setAnnotatedImages] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openTrainDialog, setOpenTrainDialog] = React.useState(false);
  const [scrollX, setscrollX] = useState(0);


  console.log(selectedImage)
  const {model_id} = useParams();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(()=>{
      let id = JSON.parse(window.localStorage.getItem('selectedImage',0));
      console.log('selectedImage', id)
      if(id===null){
        setSelectedImage(0);
      }
      else{
        setSelectedImage(id);
      }
      
  },[])
  useEffect(() => {
      let mounted = true;
      if(selectedImage>0){
        window.localStorage.setItem('selectedImage' ,selectedImage);
        document.getElementById(`image-${selectedImage}`)?.classList.add('labelling-page__selected_image');
      }
      else {
        window.localStorage.setItem('selectedImage',0);
        document.getElementById(`image-${0}`)?.classList.add('labelling-page__selected_image');
      }
      
      ProjectService.getImages({model_id}).then(
        (response) => {
          let labels=[]
          let completed = 0;
          let withLabels = [];
          let withoutLabels = [];
          for (var obj of response.data["images"]){
            let image={}
            image['id']=obj['box_id']
            image['file_id'] = obj['file_id']
            image['name']=obj['file'].split('/').at(-1)
            image['src']=( process.env.NODE_ENV==='production'?"https://demo.docextractor.com":"http://127.0.0.1:8000" ) +obj['file']
            image['regions']=JSON.parse(obj['bounding_box'])
            labels=JSON.parse(response.data['labels'])
            if(image['regions'].length>0){
              completed++;
              withLabels.push(image);
            }
            else{
              withoutLabels.push(image);
            }
            // imageList.push(image)
          }
          const imageList = withoutLabels.concat(withLabels)
          if(mounted){
            setImages(imageList)
            setLabels(labels)
            setValidModel(true);
            setLoading(false);
            setAnnotatedImages(completed);
          }
          
          // setProject(response.data);
        }
      ).catch(error=>{
        if(error?.response?.status===404){
          setLoading(false);
      }
    });
    return function cleanup(){
      mounted = false
      window.localStorage.setItem("selectedImage",0);
    }
  }, [saved,selectedImage]);

  console.log(saved)

  if(!isLoading && !isValidModel){
    return <NotFoundPage/>
  }
  const handleSave = (id, data) => {
    setSaved(false);
    ProjectService.updateProject("box", {id,"bounding_box":JSON.stringify(data)})
      .then(() => {
        console.log("Updated")
        setSaved(true)
      });
    
  };


  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  
 
  
 
  const handleNext = (param1, param2)=>{
    // console.log( images[selectedImage].regions, saved)
   
    if ( selectedImage === images.length - 1 || selectedImage > images.length-1){
      handleSave(param1, param2)
      return 
    } 
    document.getElementById(`image-${selectedImage}`)?.classList.remove('labelling-page__selected_image');
    document.getElementById(`image-${selectedImage+1}`)?.classList.add('labelling-page__selected_image');
    handleSave(param1, param2)
    setSelectedImage(selectedImage + 1)
    scrl.current.scrollLeft += 100;
    setscrollX(scrollX + 100)
    
  }
  const handlePrev = (param1, param2)=>{
    if (selectedImage === 0){
      handleSave(param1, param2)
      return
    } 
    document.getElementById(`image-${selectedImage}`)?.classList.remove('labelling-page__selected_image');
    document.getElementById(`image-${selectedImage-1}`)?.classList.add('labelling-page__selected_image');
    handleSave(param1, param2)
    setSelectedImage(selectedImage - 1)
    scrl.current.scrollLeft -= 100;
    setscrollX(scrollX - 100)
  }

  const handleDeleteImage = ()=>{
    const id = images[selectedImage].file_id
    console.log(id)
    ProjectService.deleteImage(id)
    .then(()=>{
      setSaved(true)
      if(selectedImage===images.length-1){
        setSelectedImage(prevValue =>prevValue-1)
      }
      else{
        setSelectedImage(prevValue =>prevValue)
      }
      window.localStorage.setItem('selectedImage' ,selectedImage);
      window.location.reload(false)
    })
  }
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleClickCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleClickOpenTrainDialog = () => {
    setOpenTrainDialog(true);
  };

  const handleCloseTrainDialog = () => {
    setOpenTrainDialog(false);
  };
  // var editorContainer = document.getElementById("editorDiv");
  // var toolbarContainer = document.getElementById("toolbarDiv");

  // var editor = new CanvasTools.Editor(editorContainer).api;
  // editor.addToolbar(toolbarContainer, CanvasTools.Editor.FullToolbarSet,  "../../../node_modules/vott-ct/lib/icons/");
  return (
      <div style={{marginTop:"2cm"}} className="p-2 mb-5 ">
            <div className="d-flex flex-column justify-content-center text-center align-items-center">
              {images.length>0 && labels.length>0 ?<>
              <div className="d-flex justify-content-center text-center w-75" >
                <span className="p-2 bg-primary opacity-50 text-white rounded">  
                  {annotatedImages}/{images.length} done
                </span>
                <div className="w-75 text-center">
                  <LinearProgressWithLabel value={(annotatedImages/images.length)*100} />
                </div>
              </div>
              <div style={{height:'80vh'}} className="mb-5 image-annotation-tool">
                <div className="d-flex">
                  <div className="d-flex w-50 justify-content-start align-items-center">
                      <span className="p-3 bg-white h-100 shadow rounded">{selectedImage+1} / {images.length}</span>
                  </div>
                  <div className="d-flex w-50 justify-content-end">
                    <Button 
                      onClick={handleClickOpenDeleteDialog} 
                      color={"rose"} 
                      variant="contained" 
                      startIcon={<DeleteIcon/>}
                      className="me-2"
                    >
                      Delete Image
                    </Button>
                    <Button
                      onClick={handleClickOpenTrainDialog}
                      disabled = {annotatedImages!==images.length} 
                      variant="contained" 
                      style = {{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
                    >
                      Train
                    </Button>
                  </div>
                </div>
                
                <TrainingOptionsDialog open={openTrainDialog} handleClose={handleCloseTrainDialog} />
                <DeleteImageModal openDeleteDialog={openDeleteDialog} handleClickCloseDeleteDialog={handleClickCloseDeleteDialog} image={images[selectedImage]} handleDeleteImage={handleDeleteImage}/>
                <ReactImageAnnotate
                  key = {2}
                  style={{height:'60vh',margin:"5px"}}
                  labelImages
                  regionClsList = {labels}
                  // regionTagList={["tag1", "tag2", "tag3"]}
                  selectedImage={selectedImage}
                  onNextImage={output => {handleNext(output.images[selectedImage]['id'], output.images[selectedImage].regions)}}
                  onPrevImage={output => {handlePrev(output.images[selectedImage]['id'], output.images[selectedImage].regions)}}
                  images={images} 
                  onChange={(change) => setSaved(false)}
                  onSave = {(save) => handleSave(save.images[selectedImage]['id'], save.images[selectedImage].regions)}
                  onExit={output => {
                    // console.log(output.images[selectedImage])
                    handleSave(output.images[selectedImage]['id'], output.images[selectedImage].regions)
                    setSaved(true)                  
                  }
                    
                  }
                />
              </div> 
              </>: "No Image loaded yet" }
          </div>
          {images.length>0 && <div className="d-flex w-100 justify-content-center align-items-center"> <ImagePreview scrl={scrl} images = {images} selectedImage={selectedImage} setSelectedImage = {setSelectedImage} scrollX={scrollX} setscrollX={setscrollX} /> </div> }
      </div>
  );
}