import axios from "axios";
import authHeader from "./auth.header";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const API_URL = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/api/":"http://127.0.0.1:8000/api/";

axios.defaults.withCredentials = true ;

const getUserProfile = () => {
  return axios.get(API_URL+"profile", { headers:authHeader() });
}
const sendVerificationEmail=(data)=>{
  return axios.post(API_URL+"send_verification_email/",data, { headers:authHeader() });
}
const getProjectNames = (param) => {
  // console.log(authHeader())
  return axios.get(API_URL + "project/"+param, { headers: authHeader() });
};
const getProject = (param) => {
  // console.log(authHeader())
  return axios.get(API_URL + "project/"+param, { headers: authHeader() });
};
const getImages = (param) => {
  return axios.get(API_URL + 'model/images', { headers: authHeader(),params:param });
};

const getModels = (data) => {
  return axios.get(API_URL + 'model/allmodels', { headers: authHeader(),params:data });
};

const getSingleModel = (data) => {
  return axios.get(API_URL + 'model/model', { headers: authHeader(),params:data });
};

const validateName = (type,data) =>{
  return axios.post(API_URL+"validate/"+type, data,  { withCredentials:true, headers: authHeader() });
}
const getFiles = (project) => {
  return axios.get(API_URL +'files/', { headers: authHeader(),params:{
    'id':project
  }});
};

const downloadAnnotation = (data) => {
  return axios.get(API_URL + 'model/downloadone', { headers: authHeader(),params:data });
};

const downloadCOCO = (data) => {
  return axios.get(API_URL + 'model/download' , { headers: authHeader() ,params:data});
};

const createProject = (formData) => {
  // console.log(formData)
  var header=authHeader()
  return axios.post(API_URL+"project/", formData,  { headers: header });
};

const createModel = (data) => {
  // console.log("posting")

  var header=authHeader() 
  return axios.post(API_URL+'model/' , data,  { headers: header });
};

const updateModelFiles = (data)=>{
  return axios.put(API_URL+"model/update-files/", data ,{ headers:authHeader() });
}
const uploadFiles = (new_data) => {
  // console.log("posting")
 
  var header=authHeader()

  return axios.post(API_URL+"files/", new_data,  { headers: header });
};

const uploadPreTrainedModel = (data) => {
  // console.log("posting")
 
  var header=authHeader()

  return axios.post(API_URL+"model/upload/pretrained/", data,  { headers: header });
};
const deleteProject = (proj_id) =>{
  return axios.delete(`${API_URL}project/${proj_id}`, { headers:authHeader() });
}

const deleteModel = (model_id) => {
  // console.log("posting")
  console.log(model_id)
  var header=authHeader()
  header = {...header,'Content-Type':'application/json'}
  console.log(header)
  return axios.delete(`${API_URL}model/${model_id}`,  { headers: header });
};

const deleteImage = (image_id) => {
  // console.log("posting")
  console.log(image_id)
  var header=authHeader()
  header = {...header,'Content-Type':'application/json'}
  console.log(header)
  return axios.delete(`${API_URL}files/${image_id}`,  { headers: header });
};

const updateProject = (type, new_data) => {
  // console.log("posting")
 
  var header=authHeader()

  return axios.put(API_URL+"model/"+type, new_data,  { headers: header });
};

const trainModel = (data) => {

  return axios.post(API_URL+"train/", data,  { withCredentials:true, headers: authHeader() });
} 

const resumeTraining = (data) => {

  return axios.post(API_URL+"resume-training/", data,  { withCredentials:true, headers: authHeader() });

}

const getModelMetricsData = (data) => {
  return axios.get(API_URL+"metrics-data/",  { params:data, withCredentials:true, headers: authHeader() });
} 


const testModelWithExistingFiles = (data) => {

  return axios.post(API_URL+"test/existing", data,  { withCredentials:true, headers: authHeader() });
}

const testModelWithNewFiles = (data) => {

  return axios.post(API_URL+"test/new", data,  { withCredentials:true, headers: authHeader() });
}

const getOCRData = (model_id,slug,data) => {
  return axios.post(`${API_URL}ocr/extract/${model_id}`, data ,  { withCredentials:true, headers: authHeader() });
}

const downloadOCR = (type,data) =>{
  return axios.post(`${API_URL}ocr/download/${type}`, data,  { withCredentials:true, headers: authHeader() });
}
const downloadModel = (data) => {
  var headers = authHeader();
  // headers = {...headers,
  //   'responseType': 'arraybuffer'
  // }
  // console.log(headers)
  return axios.get(API_URL+"download/model/", { headers:headers,params:data });
}

export default {
    getUserProfile,
    sendVerificationEmail,
    getProjectNames,
    getProject,
    getImages,
    getModels,
    getSingleModel,
    getFiles,
    validateName,
    createProject,
    createModel,
    updateModelFiles,
    uploadFiles,
    uploadPreTrainedModel,
    deleteProject,
    deleteModel,
    deleteImage,
    updateProject,
    downloadCOCO,
    downloadAnnotation,
    trainModel,
    resumeTraining,
    getModelMetricsData,
    testModelWithExistingFiles,
    testModelWithNewFiles,
    getOCRData,
    downloadOCR,
    downloadModel
};
