import React from 'react'

function NotFoundPage() {
    return (
        <div style={{'marginTop':'2cm'}} className='p-5 text-center'>
            <h3>Not Found</h3>
        </div>
    )
}

export default NotFoundPage
