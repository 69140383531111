export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const RETRIEVE_PROJECTS = "RETRIEVE_PROJECTS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";


export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
