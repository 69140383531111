import React, {useState, useEffect} from "react";
import { Navigate, Link, useNavigate } from 'react-router-dom';
import Header  from 'components/Header/Header.js';
import HeaderLinks  from 'components/Header/HeaderLinks.js';
import Parallax from "components/Parallax/Parallax.js";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SingleProject from './SingleProject';
import { useDispatch,useSelector } from 'react-redux';
import ProjectService from '../../services/project.service';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';//Css
import './Project.css';
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const ITEM_HEIGHT = 48;

function Projects(props) {
    const classes = useStyles();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [selectedProject,setSelectedProject] = useState(null);
    

    
    const history = useNavigate();
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const getAllProjects = () =>{
        ProjectService.getProjectNames("all").then(
            (response) => {
                setProjects(response.data);
                setLoading(false);
            }
            ).catch(error=>{
                setLoading(false);
                console.log(error)
        })
    } 
    useEffect(() => {
        getAllProjects();
    }, []);
    console.log(projects)
    const handleDelete = (proj_id) => {
        handleClose();
        const promise = toast.promise(ProjectService.deleteProject(proj_id),
        {
            'pending':'Deletin project...',
            'success':'Project deleted successfully',
            'error':'Error deleting project'
        }
        )
        promise.then(res=> getAllProjects()).catch(err=>console.log(err))
    }

    const changeRoute= (route) =>{
        history(route)
    }
    const handleClickOpen = (proj) => {
        setSelectedProject(proj)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    const { ...rest } = props;
    return (
        <div>
            <div style={{marginTop:"2cm"}} className = "p-2 justify-content-center ms-3 px-2" >
                <div style={{maxWidth:"max-content"}} className="p-2 bg-white shadow-sm rounded">
                   <Typography > All Projects</Typography >
                </div>
                {
                    loading?
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> 
                    :
                    <div className="project_container">
                        <div className="row no-gutters" >
                            <div onClick = {() => changeRoute(`/create`)}  style={{backgroundColor:"#A7BAFB",flexBasis:1,minWidth:'310px',minHeight:'150px'}} className="col-md-3 me-3 mb-4 d-flex flex-column justify-content-center rounded text-white align-items-center shadow-sm hover-pointer">
                                <div className="fs-2 mb-2">
                                    +
                                </div>
                                <Typography>
                                    Create new project
                                </Typography>
                            </div>
                            {
                                projects.length>0?projects.map((p)=>

                                <div key={p.id} style={{minWidth:'310px'}}  className="col-md-3 me-3 mb-4 py-1 px-3 text-dark rounded shadow-sm  bg-white ">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Typography className="hover-pointer" onClick = {() => changeRoute(`/project/${p.id}`)} > {p.proj_name} </Typography>
                                        <div>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={()=> handleClickOpen(p) }
                                            >
                                                <Box
                                                    component={DeleteIcon}
                                                    height = {"1rem"}
                                                    width = {"1rem"}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:'#F1F1F1',fontSize:'14px'}} className="p-2 lh-sm px-3 my-3 text-dark rounded line-clamp">
                                    {p.about}
                                    </div>
                                    <div style={{fontSize:'13px'}} className="d-flex flex-row mt-3 justify-content-between">
                                        <div>
                                            {/* <span style={{backgroundColor:"#0DFF51" ,height:"9px",width:'9px'}} className="p-1 mx-1 badge rounded-circle"> </span>
                                            completed */}
                                        </div>
                                        <div >
                                        <span className="text-secondary">Last access:</span> {p.last_access}
                                        </div>
                                    </div>
                                </div>
                                    // <SingleProject
                                    //     key={p.id}
                                    //     id={p.id} 
                                    //     name={p.proj_name} 
                                    //     descr={p.about}
                                    //     status="completed" 
                                    //     last_access="01/12/2021" 
                                    // />
                                ):<span className="fw-bold fs-5 m-5"><b>No Projects, yet!</b></span>
                            }
                        </div> 
                    </div>
                


                }
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle><span className="fs-6">{"Confirm to delete this ?"}</span></DialogTitle>
                    <DialogContent>
                    <div style={{minWidth:'20vw',minHeight:'5vh'}}></div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={(e)=> {
                            handleDelete(selectedProject?.id);
                        }} 
                        color="primary" autoFocus
                    >
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Projects
