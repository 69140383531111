import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useParams } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";
import { register } from "../../actions/auth";
import  ProjectService  from '../../services/project.service';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CheckCircleIcon  from '@material-ui/icons/CheckCircle';
import  AuthService  from '../../services/auth.service';

const useStyles = makeStyles(styles);
const modalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[5],
    width:"50%",
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    textAlign:'center'
  },
}));

export default function RegistrationPage(props) {
  // const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // setTimeout(function () {
  //   setCardAnimation("");
  // }, 700);

  const classes = useStyles();
  const modalClasses = modalStyles();
  const dispatch = useDispatch();

  const {uid,token} = useParams();

  const [password, setPassword] = useState("");
  const [password_repeat, setPasswordRepeat] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);


  
  const onChangePassword = (e) => {
    const password = e.target.value;

    if(password_repeat!=""){
      if(password_repeat !== password){
        setErrors({ 
          ...errors,
          password_repeat: "Passwords do not match"
        });
      }
      else{
        setErrors({
          ...errors,
          password_repeat: null
        });
      }
    }

    setPassword(password);
  };
  const onChangePasswordRepeat = (e) => {
    const password_repeat = e.target.value;
    if(password_repeat !== password){
      setErrors({ 
        ...errors,
        password_repeat: "Passwords do not match"
      });
    }
    else{
      setErrors({
        ...errors,
        password_repeat: null
      });
    }
    setPasswordRepeat(password_repeat);
  };
  const handleOpenDialog = ()=>{
    setOpen(true);
  }
  const handleCloseDialog = () => {
    setOpen(false);
    window.location.reload();
  }
  const handleSetPassword = (e) => {
    e.preventDefault();

    setLoading(true);
    AuthService.resetPassword({password,uid,token})
      .then((res) => {
        setLoading(false);
        handleOpenDialog();

      }).catch(err => {
        console.log("error",err.response)
        setLoading(false);
        if(err?.response?.data){
          const message = err.response.data.message;
          setErrors({...errors, message});
        }
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  console.log("errors",errors);
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer  style={{'justifyContent':'center'}} justifyContent="center">
            <GridItem xs={12} sm={12} md={5}>
              <Card >
                <form className={classes.form} onSubmit={handleSetPassword}>
                  <CardHeader style={{backgroundColor:'#556eff',color:'white'}} className={classes.cardHeader}>
                    <h2>Set Password</h2>
                  </CardHeader>
                  <CardBody className="d-flex flex-column">
                    {errors?.message && <p className="p-3 rounded text-white bg-danger bg-opacity-75 shadow" >{errors.message}</p> }
                    <input id="password" type="password" value={password} onChange={onChangePassword} required placeholder="New Password" className="input" />
                    <input id="password_repeat" type="password" value={password_repeat} onChange={onChangePasswordRepeat} required placeholder="Confirm Password" className="input" />
                    {
                      errors?.password_repeat? <label htmlFor="password_repeat" className="text-danger font-sm">*Passwords do not match</label>:null
                    }
                  
                  </CardBody>
                  <CardFooter className={classes.cardFooter + "d-flex flex-column"}>
                    <Button 
                      disabled = {loading || password!==password_repeat? true : false}
                      type="submit"  
                      variant="contained" 
                      style={{'backgroundColor':'#6e6efe'}}
                    >
                      {loading?"Processing...":"Continue"}
                    </Button>
                    
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open = { open }
            className={modalClasses.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={modalClasses.paper}>
                <Box
                  color={'green'}
                  width={'40px'}
                  height={'40px'}
                  component={CheckCircleIcon}
                />
                <h2 id="transition-modal-title" className="fs-6 fw-bold">
                  Your password was successfully changed.Please go ahead and login with new password.
                </h2>
                <Button
                  component={Link}
                  to="/login"  
                  variant="contained" 
                  style={{'backgroundColor':'#6e6efe'}}
                  onClick={handleCloseDialog}
                > 
                  Ok
                </Button>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  );
}
