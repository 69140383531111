import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './upl.css'
import Header from 'components/Header/Header'
import HeaderLinks from 'components/Header/HeaderLinks'
import Button  from 'components/CustomButtons/Button.js';
import ProjectService from "../../services/project.service"
import { FormControl, FormLabel, Select,MenuItem, Divider } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)


function UploadFiles(props) {
    const { ...rest } = props ;
    const [projectModels, setProjectModels] = useState([])
    const {proj_name}= useParams();
    const [files, setFiles] = useState([])
    const [selectedModel,setSelectedModel] = useState("")
    const [loading,setLoading] =useState(false)

    useEffect(()=>{
        ProjectService.getModels({proj_name}).then(res=>{
            setProjectModels(res.data);
            setLoading(false);
        } 
        )
    },[])
    console.log(selectedModel)
    const handleSubmit = (e) => {
        e.preventDefault();
        if(selectedModel !== ""){
            const formData = new FormData();
            formData.set('proj_name', proj_name)
            files.forEach(file => formData.append('files', file.file))
            formData.set('model_name', selectedModel)
            // for (var pair of formData.entries()) {
            //   console.log(pair[0]+ ', ' + pair[1]); 
            // }
            ProjectService.updateModelFiles(formData)
            .then((res) => {
                console.log("Files uploaded")
                console.log(res)
                alert("Files uploaded")
            });
        }
        
      };

    return (
        <div>
            <div style={{marginTop:"2cm"}} className="pt-4 mb-3 mx-5 px-5">
            <div className='d-flex model-testing-heading'>
                <HomeIcon/>
                <span className='ms-2'>
                    <Link to={`/project/${proj_name}`}>{proj_name}</Link> / Upload Files
                </span>
            </div>
                {/* <Divider/> */}
                <div className='d-flex justify-content-center mt-3'>
                    <div className='p-4 bg-white rounded shadow-sm w-75'>
                        <FormControl className='my-3 p-3'>
                            <FormLabel className='text-dark fw-bold' id="select_model_label" label="select_model">Select Model</FormLabel>
                            <Select 
                                labelId='select_model_label'
                                id="select_model"
                                value={selectedModel}
                                displayEmpty
                                onChange={(e)=>setSelectedModel(e.target.value)}
                            >
                                <MenuItem value=""><em>Select a model</em></MenuItem>
                                { projectModels.map(model => 
                                    <MenuItem key={model._id} value={model.name}>{model.name}</MenuItem>
                                )
                                }
                            </Select>
                        </FormControl>
                        <div className='fw-bold mb-3 px-3'>Upload Files</div>
                        <div style={{"border":"2px dashed gray"}} className="bg-white rounded fs-5 p-3 shadow-sm">
                            
                            <FilePond
                                files={files}
                                onupdatefiles={setFiles}
                                allowMultiple={true}
                                // maxFiles={100}
                                server="/api"
                                name="files"
                                instantUpload = "false"
                                labelIdle='<div>Drag & Drop your files or <div class="fs-5 text-primary filepond--label-action">Choose Your files</div></div>'
                                labelButtonProcessItem="Start Upload"
                                credits='false'
                                styleItemPanelAspectRatio='1'
                            />
                        </div>
                        <div className="mt-4 text-center">
                            <Button onClick={handleSubmit} className={`btn ${files.length===0 && 'disabled'}`} style={{backgroundImage:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,170,170,1) 0%, rgba(191,181,191,1) 0%, rgba(255,97,143,1) 0%, rgba(140,47,255,1) 0%, rgba(129,60,250,1) 0%, rgba(66,102,255,1) 100%, rgba(30,255,248,1) 100%)"}}>Upload</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadFiles
