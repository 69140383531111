import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import {VscJson} from 'react-icons/vsc'
import {FaFileCsv} from 'react-icons/fa'
import  ProjectService  from '../../../services/project.service';
import { useParams } from 'react-router-dom';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize:'16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white + " !important",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function DownloadOCRDropdown({data,anchorEl,handleCloseDownloadDropdown}) {

    const {model_id} = useParams();
    var fileDownload = require('js-file-download');

    const handleDownload = (type) => {
        console.log(type);
        handleCloseDownloadDropdown();
        const body = {'model_id':model_id,'data':data};
        ProjectService.downloadOCR(type,body).then(res=>{
            if(type==="json"){
                fileDownload(JSON.stringify(res.data), "ocr_results.json");
            }
            else if(type==="csv"){
                fileDownload(res.data, "ocr_results.csv");
            }

        })
    }
    return (
        <div>
        
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDownloadDropdown}
        >
            <StyledMenuItem onClick={(e)=>handleDownload("csv")}>
                <ListItemIcon>
                    <FaFileCsv className='fs-5 fw-bold text-success'/>
                </ListItemIcon>
                <ListItemText className='fs-6' primary = "CSV" />
            </StyledMenuItem>
            <StyledMenuItem onClick = {(e)=>handleDownload("json")}>
                <ListItemIcon>
                    <VscJson className = 'fw-bold fs-5 text-primary'/>
                </ListItemIcon>
                <ListItemText primary = "JSON" />
            </StyledMenuItem>
        </StyledMenu>
        </div>
    );
}