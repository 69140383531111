import { Grid,Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom';

const SingleProject = ({id,name,descr,status,last_access}) => {
    return (
            <Grid item  component={Link} to={`/project/${name}`}  className="text-dark  rounded shadow-sm  bg-white">
                <Typography>{name}</Typography>
                <div style={{backgroundColor:'#F1F1F1'}} className="p-2 px-3 my-3 text-dark rounded line-clamp">
                    {descr}
                </div>
                <div style={{fontSize:'13px'}} className="d-flex flex-row mt-3 justify-content-between">
                    <div>
                        <span style={{backgroundColor:"#0DFF51" ,height:"9px",width:'9px'}} className="p-1 mx-1 badge rounded-circle"> </span>
                        {status}
                    </div>
                    <div >
                      <span className="text-secondary">Last access:</span> {last_access}
                    </div>
                </div>
            </Grid>
    )
}

export default SingleProject
