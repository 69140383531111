import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ProjectService from '../../../services/project.service';
import MetricGraph from './MetricGraph';



function ModelMetricsPage() {
  const {model_id,slug} = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getModelMetricsData = () => {
    ProjectService.getModelMetricsData({model_id,slug}).then(res=>{
      setData(res.data.data);
      setLoading(false);
    })
  }
  useEffect(() => {
    const interval = setInterval( ()=> getModelMetricsData() ,10000)
    return ()=> clearInterval(interval)
  }, []);
  
  // console.log(data);

  return (
    <div style={{marginTop:"1.7cm",minHeight:'80vh' }} className='mb-3'>
        <div className='mx-5 pt-4'>
            <span className='h5'>Model Metrics</span>
            {
              loading?<div className='d-flex h-100 justify-content-center align-items-center'>
                <CircularProgress/>
                <span className='ms-3'>Preparing charts for you.Please wait...</span>
              </div> : 
              data?.length>0?
                <div className='chart-container'>
                  {
                      data?.map((metric,id)=>
                        <MetricGraph key={id} metric={metric}/>
                      )
                  }
                </div>
              :
              <div className='d-flex h-100 justify-content-center align-items-center'>
                <span className='ms-3 bg-danger bg-opacity-10 p-2 rounded'>No data available for this model</span>
              </div>
            }
        </div>
    </div>
  )
}

export default ModelMetricsPage