import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';
import ImageViewer from 'react-simple-image-viewer';
import { useEffect } from 'react';
const TestResultsPage = ({results}) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return <>
        <span className='h5'>Test Results</span>
        <div className='mt-3 row row-cols-3'>
        {results.map((src, index) => (
            <img
                className='m-1 col border border-2'
                src={ src }
                onClick={ () => openImageViewer(index) }
                width="300"
                key={ index }
                style={{ margin: '2px' }}
                alt=""
            />
        ))}

        {isViewerOpen && (
            <div style={{'height':'60vh !important','width':'50vw !important'}} className='pt-5'>
                <ImageViewer
                    className=""
                    src={ results }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                />
            </div>
        )}
        </div>

    </>;
};

export default TestResultsPage;
