import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";
import { register } from "../../actions/auth";
import  ProjectService  from '../../services/project.service';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CheckCircleIcon  from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);
const modalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[5],
    width:"50%",
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    textAlign:'center'
  },
}));

export default function RegistrationPage(props) {
  // const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // setTimeout(function () {
  //   setCardAnimation("");
  // }, 700);

  const classes = useStyles();
  const modalClasses = modalStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password_repeat, setPasswordRepeat] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { message } = useSelector((state) => state.message);
  const { isLoggedIn } = useSelector(state => state.auth);

  useEffect(()=>{
    setErrors({...errors, message });
  },[message])

  const onChangeFirstName = (e) => {
    const first_name = e.target.value;
    setFirstName(first_name);
  };
  const onChangeLastName = (e) => {
    const last_name = e.target.value;
    setLastName(last_name);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;

    if(password_repeat!=""){
      if(password_repeat !== password){
        setErrors({ 
          ...errors,
          password_repeat: "Passwords do not match"
        });
      }
      else{
        setErrors({
          ...errors,
          password_repeat: null
        });
      }
    }

    setPassword(password);
  };
  const onChangePasswordRepeat = (e) => {
    const password_repeat = e.target.value;
    if(password_repeat !== password){
      setErrors({ 
        ...errors,
        password_repeat: "Passwords do not match"
      });
    }
    else{
      setErrors({
        ...errors,
        password_repeat: null
      });
    }
    setPasswordRepeat(password_repeat);
  };
  const handleOpenDialog = ()=>{
    setOpen(true);
  }
  const handleCloseDialog = () => {
    setOpen(false);
    window.location.reload();
  }
  const handleRegister = (e) => {
    e.preventDefault();

    setLoading(true);
    dispatch(register({first_name , last_name, email , password}))
      .then((res) => {
        ProjectService.sendVerificationEmail({email,password}).then(res=>{
          setLoading(false);
          handleOpenDialog();
        }).catch(err=>{
          setLoading(false);
          console.log(err);
        })
      }).catch(err => {
        console.log("error",err)
        setLoading(false);
        // setErrors(message);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  console.log("errors",errors);
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer style={{'justifyContent':'center'}} justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card >
                <form className={classes.form} onSubmit={handleRegister}>
                  <CardHeader style={{backgroundColor:'#556eff',color:'white'}} className={classes.cardHeader}>
                    <h2>Registration</h2>
                  </CardHeader>
                  <CardBody className="d-flex flex-column">
                    <div className="d-flex w-100">
                      <input id="first_name" type="text" value={first_name} onChange={onChangeFirstName} required placeholder="First Name" className="input w-50 me-2" />
                      <input id="last_name" type="text" value={last_name} onChange={onChangeLastName} required placeholder="Last Name" className="input w-50 ms-2" />
                    </div>
                    <input id="email" type="email" value={email} onChange={onChangeEmail} required placeholder="Email Id" className="input" />
                    {
                      errors?.message?.email?.length>0? <label htmlFor="email" className="text-danger font-sm">*This email is already registered</label>:null
                    }
                    <input id="password" type="password" value={password} onChange={onChangePassword} required placeholder="Password" className="input" />
                    <input id="password_repeat" type="password" value={password_repeat} onChange={onChangePasswordRepeat} required placeholder="Confirm Password" className="input" />
                    {
                      errors?.password_repeat? <label htmlFor="password_repeat" className="text-danger font-sm">*Passwords do not match</label>:null
                    }
                  
                  </CardBody>
                  <CardFooter className={classes.cardFooter + "d-flex flex-column"}>
                    <Button 
                      disabled = {loading}
                      type="submit"  
                      variant="contained" 
                      style={{'backgroundColor':'#6e6efe'}}
                    >
                      {loading?"Getting Started...":"Get started"}
                    </Button>
                    <div className="mt-3">
                      <p className="text-center">
                        Already have an account? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open = { open }
            className={modalClasses.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={modalClasses.paper}>
                <Box
                  color={'green'}
                  width={'40px'}
                  height={'40px'}
                  component={CheckCircleIcon}
                />
                <h2 id="transition-modal-title" className="fs-6 fw-bold">A verification link has been sent to your registered email address.Please click on the link to verify your email address</h2>
                <Button
                  component={Link}
                  to="/login"  
                  variant="contained" 
                  style={{'backgroundColor':'#6e6efe'}}
                  onClick={handleCloseDialog}
                > 
                  Ok
                </Button>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  );
}
