import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DeleteImageModal({openDeleteDialog,handleClickCloseDeleteDialog,image,handleDeleteImage}) {

    return (
        <div>
        <Dialog onClose={handleClickCloseDeleteDialog} aria-labelledby="customized-dialog-title" open={openDeleteDialog}>
            <DialogTitle onClose={handleClickCloseDeleteDialog}>
                <span className='h6 text-capitalize'>Are you sure you want to delete this image ?</span>
            </DialogTitle>
            <DialogContent dividers className='text-center'>
            <Typography gutterBottom>
                {image?.name}
            </Typography>
            <div className='border border-2'>
                <img src={image?.src} width="50%" height="50%"/>
            </div>
            
            </DialogContent>
            <DialogActions>
            <Button 
                autoFocus 
                onClick={ () => { 
                    handleClickCloseDeleteDialog() ;
                    handleDeleteImage();
                }} 
                variant="contained" 
                color="secondary"
            >
                Yes, Delete
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
