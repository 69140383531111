import React, { useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom'
import HeaderLinks from './../../components/Header/HeaderLinks';
import  Header  from './../../components/Header/Header.js';
import { Grid, Typography,Avatar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import  Button  from 'components/CustomButtons/Button.js';
import projectService from 'services/project.service';
import ModelsComponent from './../ModelsPage/ModelsComponent';
import NotFoundPage from 'views/NotFoundPage/NotFoundPage';

import loading from './../../assets/img/loading.gif'
import UploadModelDialog from './../ModelsPage/UploadModelDialog';

function ProjectDetailPage(props) {
    const {proj_id}=useParams();
    const [isLoading,setLoading] = useState(true);
    const [project, setProject] = useState(null)
    const [list,setList]=useState([1,2,3,4,5]);
    const [images, setImages] = useState( [] )
    const [labels, setLabels] = useState([])
    const [projectFiles,setProjectFiles]= useState([])
    const [openModelUploadDialog,setOpenModelUploadDialog] = useState(false); 
    const [isModelCreated,setIsModelCreated] = useState(false);
    useEffect(()=>{
        projectService.getProject(proj_id).
            then(res=>{
                setProject(res.data);
                setLoading(false);
                return res;
            }).
            catch(error=>{
                if(error?.response?.status===404){
                    setLoading(false);
                    return error;
                }
            }
            )
    },[])

    const handleOpenModelUploadDialog = () =>{
        setOpenModelUploadDialog(true);
    }
    const handleCloseModelUploadDialog = () =>{
        setOpenModelUploadDialog(false);
    }
    const handleUploadPreTrainedModel = () =>{ 
        handleOpenModelUploadDialog() ; 
    }

    if(!isLoading && project===null){
        return <NotFoundPage/>
    }
    // const handleGetFiles=(e)=>{
    //     projectService.getFiles(proj_name).then(res=>setProjectFiles(res.data["data"]))
    // }
    const { ...rest } = props;
    return (
        <div>
            {
                isLoading?
                    <div style={{paddingTop:"2cm"}} className='d-flex min-vh-100 justify-content-center align-items-center' >
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    :<>
                    <div style={{marginTop:"2cm"}} className="d-flex flex-row align-items-center justify-content-between pt-4 mb-3 px-5">
                        <div className='fs-5'>{project.proj_name}</div>
                        <div>
                            {/* <Button component={Link} to={`/upload/${proj_name}`} style={{backgroundImage:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(219,67,67,1) 0%, rgba(191,181,191,1) 0%, rgba(255,97,143,1) 0%, rgba(140,47,255,1) 0%, rgba(129,60,250,1) 0%, rgba(120,66,251,1) 0%, rgba(67,251,122,1) 0%, rgba(86,185,145,1) 0%, rgba(66,102,255,1) 100%, rgba(30,255,248,1) 100%)"}} className=" mx-1">Upload Files</Button> */}
                            <Button 
                                // startIcon={AddIcon}
                                component = {Link} 
                                to={`/create-model/${proj_id}`} 
                                style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} 
                                className = "mx-1"
                            >
                                 New model
                            </Button>
                            <Button 
                                onClick={handleUploadPreTrainedModel} 
                                style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} 
                                className="mx-1"
                            >
                                Upload Pre-Trained model
                            </Button>
                            <UploadModelDialog openModelUploadDialog={openModelUploadDialog} handleCloseModelUploadDialog = {handleCloseModelUploadDialog} setIsModelCreated={setIsModelCreated} />
                        </div>

                    </div>
                    <div style={{backgroundColor:"#E7E7E7",minHeight:'20vh'}} className="py-2 p-3 px-5 border border-2  w-100">
                        <h5><b>About</b></h5>
                        {project.about}        
                    </div>
                    {/* <div  className="m-5 px-5">
                        <Grid container spacing={2} className="p-5 bg-white rounded border rounded-3 shadow-sm">
                        <Grid item xs={3} style={{backgroundColor:"#f4f4f4"}} className="m-2 p-3 rounded shadow-sm border" >
                                <div className="text-secondary" ><b>CONTRIBUTORS</b></div>
                                <ul style={{listStyle:'none',marginLeft:'-2rem',marginTop:'1rem'}} className=" bg-transparent">
                                    <li className="d-flex mb-1 flex-row text-center align-items-center"><Avatar style={{width:"30px",height:"30px"}} className="me-2 bg-primary">H</Avatar>Hrishikesh</li>
                                    <li className="d-flex mb-1 flex-row text-center align-items-center"><Avatar style={{width:"30px",height:"30px"}} className="me-2 bg-danger">R</Avatar>Rahul</li>
                                </ul>
                            </Grid>
                            <Grid item xs={3} style={{backgroundColor:"#f4f4f4"}} className="m-2 p-3 rounded shadow-sm border">
                                <div className="text-secondary" ><b>STATUS</b></div>
                                <div className="my-3">
                                <span style={{backgroundColor:"#0DFF51" ,height:"9px",width:'9px'}} className="p-1 badge rounded-circle"> </span> Completed
                                </div>
                                
                            </Grid>
                            <Grid item xs={3} style={{backgroundColor:"#f4f4f4"}} className="m-2 p-3 rounded shadow-sm border">
                                <div className="text-secondary" ><b>TOTAL MODELS</b></div>
                                <div className="mt-3">Completey Trained : 3</div>
                                <div>Partially Trained : 2</div>
                            </Grid>
                        </Grid>
                    </div> */}
                    <div className='mt-3'>
                        <ModelsComponent proj_id = {proj_id} isModelCreated={isModelCreated} />
                    </div>
                    {/* <div className="m-5 px-5">
                        { projectFiles.length>0?<div className="p-4 bg-white hover-shadow shadow-sm rounded">
                                <h5><b>Files</b></h5> 
                                {projectFiles.map(file=><div key={file.id} className='file-item rounded p-2 border-bottom'>{file.file_name}</div> )}
                            </div>:
                            <Button 
                                onClick={handleGetFiles} 
                                style={{backgroundImage:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,170,170,1) 0%, rgba(191,181,191,1) 0%, rgba(255,97,143,1) 0%, rgba(140,47,255,1) 0%, rgba(129,60,250,1) 0%, rgba(66,102,255,1) 100%, rgba(30,255,248,1) 100%)"}}
                            >
                                View Files
                            </Button>
                        }
                        
                    </div> */}
                    </>
            }
        </div>
    )
}

export default ProjectDetailPage
