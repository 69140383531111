import { Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import projectService from 'services/project.service';

function ModelsComponent({proj_id,isModelCreated}) {
    const [projectModels, setProjectModels] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        projectService.getModels({proj_id}).then(res=>{
            setProjectModels(res.data);
            setLoading(false);
        } 
        )
    },[isModelCreated])
    console.log(projectModels)
    return (
        <div className='bg-white rounded shadow-sm mx-5 p-4 py-2 pb-3'>
            <h5><b>Models</b></h5>
            <div className='text-center'>
                {
                    loading?
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> 
                    :
                        projectModels.length>0?
                        <div>
                        {
                            projectModels.map(
                                (p)=>
                                <div 
                                    key={p._id} 
                                    className='d-flex flex-row justify-content-between model-item py-2 px-3'
                                >
                                    <Link to={`/model/${p._id}`}>
                                         <Typography>
                                             {p.name}
                                         </Typography>
                                    </Link>
                                    {/* <span>
                                        {p.labels}
                                    </span>  */}
                                </div>
                            
                            )
                        }
                        </div>:
                        <span className='bg-danger bg-opacity-75 p-1 rounded text-white'>No model found</span>

                }
            </div>

        </div>
    )
}

export default ModelsComponent
