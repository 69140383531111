import React,{useState} from 'react'
import {toast} from 'react-toastify'
import { Button ,Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ProjectService from '../../../services/project.service';

function ResumeTrainingDialog({openResumeTrainingDialog,setOpenResumeTrainingDialog,model_id}) {

    // console.log(model_version_id);
    const handleResumeTraining = () =>{
        setOpenResumeTrainingDialog(false);
        const promise = toast.promise(ProjectService.resumeTraining({model_id}),
                          {
                            'pending':'Resuming Training...',
                            'success':'Training Completed',
                            'error':'Training Failed'
                          } ,
                          {
                            autoClose: false,
                          }    
        )
        
        promise.then(
        (response) => {
            handleClose();
            setLoading(false);
            getModelDetails();
        }

        ).catch(error=>{
        console.log(error);
        toast.error(error?.response?.data?.message)
        // alert("Error occured while training. Please try again.")
        });
    }
    const handleClose = () => {
        setOpenResumeTrainingDialog(false);
    };
    return (
        <Dialog
            open={openResumeTrainingDialog}
            
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle><span className="fs-6">{"Confirm to resume training?"}</span></DialogTitle>
            <DialogContent>
                <div style={{minWidth:'20vw',minHeight:'1vh'}}></div>
            </DialogContent>
            <DialogActions>
            <Button variant='contained' onClick={handleClose} color="secondary" style={{textTransform:'none'}}>
                Cancel
            </Button>
            <Button
                variant='contained'
                onClick={(e)=> {
                    handleResumeTraining()
                    handleClose();
                }} 
                color="primary" 
                autoFocus
                style={{textTransform:'none'}}
            >
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResumeTrainingDialog