import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {IconButton} from '@material-ui/core';
import {MdContentCopy} from 'react-icons/md';
import { useEffect } from 'react';

function APIDetails() {
    const {model_id} = useParams();

    const [copyMessage, setCopyMessage] = useState("");
    const domain = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/api/":"http://127.0.0.1:8000/api/";
    const api_url = `${domain}ocr/extract/${model_id}`;


    // useEffect(() => {
    //     setCopyMessage("")
    // }, [selected_model]);
    const copyText = () =>{
        navigator.clipboard.writeText(api_url);
        setCopyMessage("Copied!");
    }
    return (
        <div className='bg-white p-4 mt-4 rounded shadow-sm'>
            <span className='h6'>API Details</span>
            <div className='mt-3 mx-3 align-items-center bg-secondary bg-opacity-10 p-3 rounded'>
                <div style={{width:'max-content'}} className='py-1 px-2 rounded text-white bg-success'>POST</div> 
                <div>
                    <div className='my-4 d-flex align-items-center'>
                        <div className='fw-bold opacity-75'>Request URL : </div>
                        <div onClick={copyText} style={{width:'40vw'}} className='ms-1 bg-secondary d-inline-block text-truncate bg-opacity-10 rounded p-2 hover-pointer word-break'>{api_url}</div>
                        <IconButton size='medium' onClick = {copyText}><MdContentCopy/></IconButton>
                        {
                            copyMessage!==""?<div className='m-2 p-2 rounded text-white bg-success bg-opacity-75 '>{copyMessage}</div>:null
                        }
                        
                    </div>
                    <div className='my-4'><span className='fw-bold opacity-75'>Request Body : </span><span className='bg-secondary bg-opacity-10 rounded p-2 hover-pointer'>
                        files : [list of image files]   
                    </span></div>
                </div >
            </div>
        </div>
    )
}

export default APIDetails