import React, {useState, useEffect} from "react";
import { Navigate, Link } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Folder from '@material-ui/icons/Folder';
import profile from "assets/img/faces/christian.jpg";
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";
import { logout } from "../../actions/auth";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ProjectService from "../../services/project.service"



const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const [project, setProject] = useState([]);
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  var fileDownload = require('js-file-download');
  useEffect(() => {
    ProjectService.getProjectNames().then(
      (response) => {
        setProject(response.data);

      },
      (error) => {
        console.log("err"+error);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

          setProject(_content);
          // dispatch(logout());
          // props.history.push("/login");
          // window.location.reload();
      }
    );
  }, []);
  
  const gotoProj=()=>{
    props.history.push("/image_upl");
    window.location.reload();
  }

  const handleDownload = (projectName) => {
    ProjectService.downloadCOCO(projectName).then(
      (response) => {
        fileDownload(JSON.stringify(response.data), projectName+".json");
      },
      (error) => {
        console.log("err"+error);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          console.log("err"+_content);
          // setProject(_content);
          // dispatch(logout());
          // props.history.push("/login");
          // window.location.reload();
      }
    );
  }


  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const listItems =  project.map((d) => <ListItem button component={Link} to={`/label/${d.proj_name}`} alignItems='flex-start'>
  <ListItemIcon>
    <Folder/>
  </ListItemIcon>
  <ListItemText
    primary={d.proj_name}
   />
   <ListItemSecondaryAction>
   <IconButton edge="end" aria-label="CloudDownloads" onClick={(event)=>handleDownload(d.proj_name,event)}>
      <CloudDownload />
    </IconButton>
   
    </ListItemSecondaryAction>
</ListItem>);
  return (
    <div>
      
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  
                  <div className={classes.name}>
                    <h3 className={classes.title}>{currentUser.username}</h3>
                    <h6>{currentUser.email}</h6>
                    
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            
            <GridContainer justify="center">
            
                
                
                  <List >
                    
                        {listItems}
                    
                  </List>
                
                  <Button color="primary" round onClick={gotoProj}>Create New Project</Button>
            
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
