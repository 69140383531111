import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function ImagePreview({scrl,images,selectedImage,setSelectedImage,scrollX, setscrollX}) {
     // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    

    useEffect(() => {
        //Check width of the scollings
        if (
          scrl.current &&
          scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    const handleSelect = (id)=>{
        console.log("Selected")
        document.getElementById(`image-${selectedImage}`)?.classList.remove('labelling-page__selected_image');
        document.getElementById(`image-${id}`)?.classList.add('labelling-page__selected_image');
        setSelectedImage(id)
    }
    const handleScroll = (e) => {
        setscrollX(e.target.scrollLeft);
        setscrolEnd(e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth);
    }
    
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
    
        //For checking if the scroll has ended
       
    };
    // const scrollCheck = () => {
    //     setscrollX(scrl.current.scrollLeft);
    //     if (
    //       Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
    //       scrl.current.offsetWidth
    //     ) {
    //       setscrolEnd(true);
    //     } else {
    //       setscrolEnd(false);
    //     }
    // };
    
    return (
        <div className="d-flex flex-row mt-5 m-3 justify-content-center align-items-center overflow-hidden shadow-sm" style={{'width':"800px",'backgroundColor':'rgb(256 256 256)','borderRadius':'10px'}}>
            {/* {scrollX !== 0 && ( */}
                <Button variant='contained' color='primary' className="px-1" onClick={() => slide(-100)}>
                <i className="fa fa-angle-left"></i>
                </Button>
            <ul ref={scrl} className='d-flex overflow-auto w-75 hide-scrollbar mx-3 align-items-center' style={{'listStyle':'none'}}>
                {
                    images?.map((image, index) => {
                    return (
                        <li className="m-1">
                        <LazyLoadImage
                            id={`image-${index}`}
                            alt={image.alt}
                            height={100}
                            src={image.src} // use normal <img> attributes as props
                            width={100} 
                            className="labelling-page__preview_image"
                            onClick={()=>handleSelect(index)}
                        />
                        </li>
                    )
                    })
                }
            </ul>
            {/* {!scrolEnd && ( */}
                <Button variant='contained' color='primary' className="next" onClick={() => slide(+100)}>
                <i className="fa fa-angle-right"></i>
                </Button>
            {/* )} */}
        </div>
    )
}

export default ImagePreview