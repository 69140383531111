/*eslint-disable*/
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { logout } from "../../actions/auth";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const logOut = () => {
    dispatch(logout());
  };
  const navb= currentUser ? 
  [
    //     <Link to={"/profile"} className={classes.dropdownLink}>
    //       Profie
    //     </Link>
    //  ,  
        <a href="/login" className={classes.dropdownLink} onClick={logOut}>
          Logout
        </a>
      
   ]: 
    [
        <Link to={"/login"} className={classes.dropdownLink}>
          Login
        </Link>
     ,
        <Link to={"/register"} className={classes.dropdownLink}>
           Register
        </Link>]
     
  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={currentUser?.username || "Guest" }
          buttonProps={{
            className: classes.navLink,
            style: {'textTransform':'none','fontSize':'15px'},
            color: "transparent",
          }}
          buttonIcon={Apps} 
          dropdownList = {navb}
          
        />
      </ListItem>
    </List>
  );
}
