import React from 'react'

function ProcessingForOCR() {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className='ms-3'>Processing For OCR ...</div>
        </div>
    )
}

export default ProcessingForOCR