import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import auth from 'reducers/auth';

const PrivateRoute = ({children , auth, ...rest }) => (
    auth.isLoggedIn? children: <Navigate to="/login"/>
//   <Route
//     {...rest}
//     render={(props) => {
//       if (auth.isLoading) {
//         console.log("loading component")
//         return <h2>Loading...</h2>;
//       } else if (auth.isAuthenticated) {
//         console.log("Same component")
//         return children;
//       }
//        else {
//         console.log("login component")
//         return <Navigate  to="/login" />;
//       }
//     }}
//   />
);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);