import React, {useState, useCallback,useEffect,} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import '../UploadImage/upl.css'
import { toast } from 'react-toastify';

// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Button from 'components/CustomButtons/Button.js';
import Header from "components/Header/Header.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ProjectService from "../../services/project.service"
import { Link, useNavigate, useParams } from 'react-router-dom';
import  HomeIcon from '@material-ui/icons/Home';
import { Box, FormControl, FormHelperText, FormLabel, Input } from '@material-ui/core';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const useStyles = makeStyles(styles);

export default function CreateModel(props) {
  const classes = useStyles();
  const {proj_id,proj_name} = useParams();

  const history = useNavigate();
  
  const { ...rest } = props;
  const [files, setFiles] = useState([])
  const [model_name, setModelName] = useState("")
  const [labels, setLabels] = useState("")
  const [loading,setLoading] = useState(false);
  const [showError,setShowError] = useState(false)
  const [isValidName, setIsValidName] = useState(null);

  // useEffect(() => {
  //     ProjectService.validateName('model',{model_name,proj_id}).then(res => {
  //         setIsValidName(true);
  //     }).catch(error => {
  //         setIsValidName(false);
  //     })
  // }, [model_name])

  const dispatch = useDispatch();
  const onChangeLabel = (e) => {
    // console.log(e.target.value)
    const labels = e.target.value;
    setLabels(labels);
  };

  const onChangeModelName = (e) => {
    // console.log(e.target.value)
    setShowError(false)
    const modelName = e.target.value;
    setModelName(modelName);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    var labs=labels.split(',')
    var labls= labs.map(function (el) {
      return el.trim();
    })

    const formData= new FormData();
    formData.set('proj_id', proj_id)
    formData.set('model_name',model_name)
    files.forEach(file => formData.append('files', file.file))
    formData.append('labels', JSON.stringify(labls))
    formData.append('bounding_box', JSON.stringify([]))
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }
    setLoading(true);
    const promise = toast.promise(
                      ProjectService.createModel(formData),
                      {
                        'pending':"Creating...",
                        'success':'Model created successfully',
                        'error':'Error occured while creating new model'
                      },
                      {
                        autoClose: 15000
                      }
                    )
      promise.then((res) => {
        // console.log("worked")
        setLoading(false);
        history(`/label/${res.data.model_id}`);
        // window.location.reload();
      }).catch(e=>{
        setLoading(false);
        setShowError(true)
        setIsValidName(false);
        console.log(e)
        console.log(e?.response);
      });
    
  };

  return (
      
      <div style={{marginTop:"2cm"}} className='pt-4 mb-3 mx-5 px-5'>
            <div className='d-flex model-testing-heading'>
                <HomeIcon/>
                <span className='ms-2'>
                    <Link to={`/project/${proj_id}/${proj_name}`}>{proj_name}</Link> / Create Model
                </span>
            </div>
            <h4 className='text-center'><b>Create a new model</b></h4>
            <div className="bg-white mx-5 p-4 shadow rounded">
            <div className='p-4'>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
              >
                  <FormLabel>Model name</FormLabel>
                  <Box
                      className="mx-3"  
                      placeholder="Enter model name"
                      name="proj_name"
                      onChange={onChangeModelName}
                      component={Input}
                      autoComplete="off"
                      type="text"
                  />
                  <FormHelperText >{showError?isValidName?<span className='text-success'>*Name Available</span>:<span className='text-danger'>*Model with this name already exists</span>:null}</FormHelperText>
              </FormControl>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
              >
                  <FormLabel>Labels</FormLabel>
                  <Box
                      className="mx-3"
                      value={labels}  
                      placeholder="Enter labels (comma separated)"
                      name="labels"
                      onChange ={ (e) =>onChangeLabel(e)}
                      component={Input}
                      autoComplete="off"
                      type="text"
                  />
              </FormControl>
              <div style={{"border":"2px dashed gray"}} className="bg-white w-100 rounded fs-5 p-3 shadow-sm">
                  <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple={true}
                      // maxFiles={100}
                      server="/api"
                      name="files"
                      instantUpload = "false"
                      labelIdle='<div>Drag & Drop your files or <div class="fs-5 text-primary filepond--label-action">Choose Your files</div></div>'
                      labelButtonProcessItem="Start Upload"
                      credits='false'
                      styleItemPanelAspectRatio='1'
                  />
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <Button 
                  disabled={loading || (files.length<=0 || model_name==="" || labels==="" || (showError && !isValidName))} 
                  onClick={handleSubmit}
                  variant="contained"
                  style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
      </div>
  );
}
