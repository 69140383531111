import React from 'react';
import clsx from 'clsx';
import '../../App.css'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import logo from '../../assets/img/logo/logo.png';
import { Link } from 'react-router-dom';
//material-ui icons
import AppsIcon from '@material-ui/icons/Apps';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Box, Hidden } from '@material-ui/core';
import HeaderLinks from 'components/Header/HeaderLinks';
import { useSelector } from 'react-redux';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const sideBarLinks = [
    {
      path: "/create",
      name: "New Project",
      icon: AddCircleOutlineIcon,
      iconColor: "PrimaryLight",
    },
    {
      path: "/",
      name: "My Projects",
      icon: AppsIcon,
      iconColor : "Primary",
    },
  
]
const authSideBarLinks = [
    {
        path: "/login",
        name: "Login",
        icon: VpnKeyIcon,
        iconColor: "PrimaryLight",
    },
    {
        path: "/register",
        name: "Register",
        icon: AccountCircleIcon,
        iconColor: "Primary",
    },
]

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));



export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { user: currentUser } = useSelector((state) => state.auth);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color='default'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >  
            <MenuIcon color='inherit'/>
          </IconButton>
          {
            open == false?<div className="brand-title">
              <Link to={'/'}>
                  <div className="d-flex align-items-center">
                  <img src={logo} height="40" width="40"/>
                  <div className="fs-5 fw-bold ps-3">DocExtractor</div>
                  </div>
              </Link>
            </div>:null
          }
          <div style={{right:3}} className='position-absolute'>
            <Hidden smDown implementation="css" >
              <HeaderLinks/>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {
            open?<div className="ps-3 brand-title">
              <Link to={'/'}>
                  <div className="d-flex align-items-center">
                  <img src={logo} height="40" width="40"/>
                  <div className="fs-5 fw-bold ps-2">DocExtractor</div>
                  </div>
              </Link>
            </div>:null
          }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          { currentUser?sideBarLinks.map((linkObj, index) => (
            <ListItem component={Link} to={linkObj.path} key={linkObj.name} className="sidebar-link">
              <ListItemIcon>
                <Box
                  component={linkObj.icon}
                  width="1.5rem!important"
                  height="1.5rem!important"
                  // color={linkObj.iconColor}
                  // className={classes["text" + linkObj.iconColor]}
                />
              </ListItemIcon>
              <ListItemText primary={linkObj.name} />
            </ListItem>
          )) : authSideBarLinks.map((linkObj, index) => (
            <ListItem component={Link} to={linkObj.path} key={linkObj.name} className="sidebar-link">
              <ListItemIcon>
                <Box
                  component={linkObj.icon}
                  width="1.5rem!important"
                  height="1.5rem!important"
                  // color={linkObj.iconColor}
                  // className={classes["text" + linkObj.iconColor]}
                />
              </ListItemIcon>
              <ListItemText primary={linkObj.name} />
            </ListItem>
          )) }
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      {/* <main className={classes.content}>
        <div className={classes.toolbar} />
      </main> */}
    </div>
  );
}
