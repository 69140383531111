import React from 'react'

function ModelSummary({metrics,selected_model}) {
    console.log(metrics)
  return (
    <div>
        <div className='my-4 p-4 bg-white shadow-sm rounded'>
            <span className='h6'>Model Summary</span>
            {/* {selected_model} */}
            <div style={{backgroundColor:'#e0e9ff'}} className='d-flex flex-wrap mt-3 mx-3 rounded'>
                {
                    metrics[selected_model]?Object.keys(metrics[selected_model]).map((key,index)=>{
                        return (
                            <div key={index} className='d-flex border-bottom border-bottom-1 py-1 px-4 align-items-center justify-content-between w-50'>
                                <div className='px-4 fw-bold'>{key}</div>
                                <div style={{width:'max-content'}} className='col-8 p-1 px-2 bg-primary bg-opacity-25 rounded shadow-sm'>{metrics[selected_model][key]}</div>
                            </div>
                        )
                    }):null
                }
            </div>
        </div>
    </div>
  )
}

export default ModelSummary