import axios from "axios";

const API_URL = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/api/":"http://127.0.0.1:8000/api/";

const register = ( data) => {
  return axios.post(API_URL + "register/", data ,{
    headers:{
      "Content-Type":"application/json",
    }
  });
};

const login = (data ) => {
  return axios
    .post(API_URL + "token/", data)
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const sendResetPasswordLink = (data)=>{
  return axios.post(API_URL + "password_reset/", data)
}

const resetPassword = (data)=>{
  return axios.post(API_URL + "password_reset_confirm/", data)
}
export default {
  register,
  login,
  logout,
  sendResetPasswordLink,
  resetPassword,
};
