import React, { useState,Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter,Route, useNavigate, Routes, Navigate } from "react-router-dom";
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import RegistrationPage from "views/RegistrationPage/RegistrationPage.js";
import LabellingPage from "views/LabellingPage/LabellingPage";
import UploadImage from "views/UploadImage/UploadImage"
import Projects from 'views/ProjectsPage/Projects';
import ProjectDetailPage from 'views/ProjectsPage/ProjectDetailPage';
import './App.css' 
import UploadFiles from './views/UploadImage/UploadFiles';
import CreateModel from './views/ModelsPage/CreateModel';
import CreateNewProject from './views/ProjectsPage/CreateNewProject';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';
import ModelDetailsPage from './views/ModelsPage/ModelDetailsPage';
import HeaderLinks from './components/Header/HeaderLinks';
import Header from "./components/Header/Header";
import ModelMetrics from './views/ModelsPage/ModelMetrics';
import ModelTestingPage from './views/ModelsPage/ModelTesting/ModelTestingPage';
import { useSelector } from 'react-redux';
import { useEffect } from "react";
import OCRPage from "views/ModelsPage/ModelOCR/OCRPage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "views/Sidebar/Sidebar";
import AuthSidebar from "views/Sidebar/AuthSidebar";
import ProjectService  from 'services/project.service';
import PrivateRoute from "components/PrivateRoute.js";
import ForgetPasswordPage from './views/LoginPage/ForgetPasswordPage';
import SetPasswordPage from './views/LoginPage/SetPasswordPage';
import ModelMetricsPage from "views/ModelsPage/ModelMetrics/ModelMetricsPage";

toast.configure()

function App(props) {
    var hist = createBrowserHistory();
    const { ...rest } = props;
    const { user: currentUser } = useSelector((state) => state.auth);

    const [error,setError] = useState(false);
    // if (!currentUser) {
    //     console.log(currentUser)
    //     return 
    // }
    console.log(error)
    useEffect(() => {
      window.process = {
        ...window.process,
      };
      ProjectService.getUserProfile().then(res=>{
        console.log(res)
      }).catch(err=>{
        console.log(err?.response?.status)
        if(err?.response?.status === 401){
          localStorage.removeItem('user');
        }
      })
    }, []);
    return (
        <div>
          <BrowserRouter history={hist}>
              {/* <Header
                color="white"
                brand="DocExtractor"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll = {{
                height: 10,
                color: "white",
                }}
                {...rest}
              /> */}
              <div className="d-flex">
                {currentUser?<Sidebar/>:<AuthSidebar/>}
                {/* { error && <Navigate to="/login" />} */}
                {/* { !currentUser && <Navigate to="/login" /> } */}
                <div className="w-100">
                <Routes>
                  
                  <Route exact path="/login" element={<LoginPage/>}/>
                  <Route exact path="/register" element={<RegistrationPage/>}/>
                  <Route exact path="/forget-password" element={<ForgetPasswordPage/>}/>
                  <Route exact path="/password-reset-confirm/:uid/:token" element={<SetPasswordPage/>}/>
                  <Route exact path="/" element={ <PrivateRoute> <Projects /> </PrivateRoute>}/>
                  {/* <PrivateRoute exact={true} path="/" ={<Projects/>}/> */}
                  <Route exact path="/landing-page" element={<PrivateRoute> <LandingPage /> </PrivateRoute>}/>
                  <Route exact path="/profile" element={<PrivateRoute> <ProfilePage /> </PrivateRoute>}/>
                  <Route path="/label/:model_id" element={<PrivateRoute> <LabellingPage /> </PrivateRoute>}/>
                  <Route exact path="/project/:proj_id" element={<PrivateRoute> <ProjectDetailPage /> </PrivateRoute>}/>
                  <Route exact path="/upload/:proj_name" element={<PrivateRoute> <UploadFiles /> </PrivateRoute>}/>
                  <Route exact path="/create-model/:proj_id" element={<PrivateRoute> <CreateModel /> </PrivateRoute>}/>
                  <Route exact path="/model/:model_id" element={<PrivateRoute> <ModelDetailsPage /> </PrivateRoute>}/>
                  <Route exact path="/model/:proj_name/:model_name/model-metrics" element={<PrivateRoute> <ModelMetrics /> </PrivateRoute>}/>
                  <Route path="/model/:model_id/test/" element={<PrivateRoute> <ModelTestingPage /> </PrivateRoute>}/>
                  <Route path="/model/:model_id/ocr/" element={<PrivateRoute> <OCRPage /> </PrivateRoute>}/>
                  <Route path="/model/:model_id/metrics/" element={<PrivateRoute><ModelMetricsPage/></PrivateRoute>}/>
                  <Route path="/create" element={<PrivateRoute> <CreateNewProject /> </PrivateRoute>}/>
                  <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
                
                </div>
                </div>
          </BrowserRouter>
      </div>
    );
}

export default App;