import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import '../ModelsPage.css';

function MetricGraph({metric}) {
    const options = {
        title: {
          text: metric?.title
        },
        xAxis:{
            title:{
                text:'epochs'
            }
        },
        yAxis:{
            title:{
                text:metric?.title
            }
        },
        series: [{
          name:metric?.title,
          data: metric?.data,
          
        }],
        credits:{
            enabled:false
        }
    }
    // console.log(options)
    return (
        <div className='single-chart-container'>
            <HighchartsReact
                highcharts = {Highcharts}
                options = {options}
            />  
        </div>
    )
}

export default MetricGraph