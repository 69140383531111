import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FilePond, File, registerPlugin } from 'react-filepond'
import ProjectService from '../../../services/project.service';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});



const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
function UpdateModelFilesDialog({open, handleClickOpen, handleClose,model_id,project_id}) {
    const [files, setFiles] = useState([]);

    const handleUplaodFiles = () =>{
        const formData = new FormData();
        formData.set("model_id",model_id)
        formData.set("project_id",project_id)
        files.forEach(file => formData.append('files', file.file))
        console.log(files)
        const promise = toast.promise(ProjectService.updateModelFiles(formData),
          {
            "pending":"Uploading Files",
            "success":"Files uploaded successfully",
            "error":"Error while uplaoding files"
          },
          {
            autoClose: false,
          }
        ) 
        promise.then(()=>{
          setFiles([])
        })
    }
    return (
      <div>
        
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" className="text-capitalize" onClose={handleClose}>
          <span className='text-capitalize fs-5' >Upload Files</span>
          </DialogTitle>
          <DialogContent dividers>
            <div style={{"border":"2px dashed gray","minWidth":"40vw"}} className="m-3 bg-white rounded fs-5 p-3 shadow-sm">
                <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    // maxFiles={100}
                    server="/api"
                    name="files"
                    instantUpload = "false"
                    labelIdle='<div>Drag & Drop your files or <div class="fs-5 text-primary filepond--label-action">Choose Your files</div></div>'
                    labelButtonProcessItem="Start Upload"
                    credits='false'
                    styleItemPanelAspectRatio='1'
                />
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleUplaodFiles} color="primary">
              Upload Files
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default UpdateModelFilesDialog


