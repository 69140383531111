import React,{ useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

import AuthService from "services/auth.service";


const useStyles = makeStyles(styles);

export default function ForgetPasswordPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [ linkSent,setLinkSent] = useState(false);

  const classes = useStyles();
  const { isLoggedIn } = useSelector(state => state.auth);


  console.log(errors);
  const onChangeEmail = (e) => {
    // console.log(e.target.value)
    const email = e.target.value;
    setEmail(email);
  };

 
  const handlePasswordReset = (e) => {
    e.preventDefault();

    setLoading(true);
    // console.log(username)
    AuthService.sendResetPasswordLink({email})
      .then(() => {
        // console.log("worked")
        setLoading(false);
        setLinkSent(true);
      }).catch(err => {
          setLoading(false);
          setErrors(err.response.data);
      });
      
  };
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
  
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer style={{'justifyContent':'center'}} justifyContent="center">
            {
              linkSent ? <GridItem xs={12} sm={12} md={5}>
                <Card>
                  <CardHeader style={{backgroundColor:'#556eff',color:'white'}} className={classes.cardHeader}>
                    <h2>Forget Password</h2>
                      
                  </CardHeader>                  
                  <CardBody style={{'minHeight':'25vh'}}>
                    <div className="p-2 fs-6 bg-success text-white bg-opacity-75 rounded shadow">Password reset link have been successfully sent to your email address.Please click the link and set a new password for your account.</div>
                  </CardBody>
                </Card>
              </GridItem>:

              <GridItem xs={12} sm={12} md={5/12*12}>
                <Card>
                  <form className={classes.form} onSubmit={handlePasswordReset}>
                    <CardHeader style={{backgroundColor:'#556eff',color:'white'}} className={classes.cardHeader}>
                      <h2>Forget Password</h2>
                      
                    </CardHeader>
                  
                    <CardBody className="d-flex flex-column">
                      <div>
                        {errors?.message?.email?.length>0 && <p className="p-3 rounded text-white bg-danger bg-opacity-75 shadow">{errors?.message?.email}</p>}
                      </div>
                      <input id="email" type="email" value={email} onChange={onChangeEmail} placeholder="Email Address" className="input" />
                    </CardBody>
                    <CardFooter className={classes.cardFooter + "d-flex flex-column"}>
                      <Button
                        disabled = {email==="" || loading ? true : false}
                        type="submit"
                        variant="contained" 
                        style={{'backgroundColor':'#6e6efe'}}
                      >
                        { loading?"Processing...":"Send Password Reset Link" }
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            }
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
