import Header from 'components/Header/Header';
import React from 'react'
import HeaderLinks from './../../components/Header/HeaderLinks';
import { Link, useParams, useMatch, useLocation, matchPath, useNavigate  } from 'react-router-dom';
import { useEffect } from 'react';
import ProjectService from "../../services/project.service"
import { useState } from 'react';
import ModelFiles from './ModelFiles';
import  NotFoundPage  from 'views/NotFoundPage/NotFoundPage';
import TrainingOptionsDialog from './TrainingOptionsDialog';
import ModelTrainingMetrics from './ModelMetrics/ModelTrainingMetrics';
import { Button ,Dialog, DialogActions, DialogContent, DialogTitle, IconButton,Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UploadModelDialog from './UploadModelDialog';
import  HomeIcon  from '@material-ui/icons/Home';
import TrainedModels from './TrainedModels';
import EditModelDialog from './EditModelDialog';
import { toast } from 'react-toastify';
import APIDetails from './APIDetails';
import ModelSummary from './ModelSummary';
import ModelHistory from './ModelHistory';
import ResumeTrainingDialog from './Helper/ResumeTrainingDialog';


function ModelDetailsPage(props) {
    const location = useLocation();
    // const pathname = matchPath)
    const url = location.pathname;
    const {model_id} = useParams();
    const history = useNavigate();

    const [model,setModel] = useState(null)
    const [proj_name,setProjName] = useState('')
    const [isLoading,setLoading] = useState(true);
    const [openEditDialog,setOpenEditDialog] = useState(false);
    const [openResumeTrainingDialog,setOpenResumeTrainingDialog] = useState(false);
    const [labels,setLabels] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [openDeleteModal,setOpenDeleteModal] = useState(false);
    const [isModelUpdated,setIsModelUpdated] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selected_model,setSelectedModel] = React.useState('');

    const menuOpen = Boolean(anchorEl);
    
    // console.log(model)
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getModelDetails(){
        ProjectService.getSingleModel({model_id}).then(res=>{
            setModel(res.data)
            const obj= JSON.parse(res.data.labels)
            setLabels(obj)
            setProjName(res.data.proj_name)
            // return ress
            setLoading(false);
        }).
        catch(error=>{
            console.log(error?.response?.status)
            if(error?.response?.status===404){
                // console.log("in error")
                setLoading(false);
                return error;
            }
        })
    }

    useEffect(()=>{
        getModelDetails();
    },[isModelUpdated]);

    if(model && selected_model==''){
        const his = Object.keys(model.model_history);
        if(his.length>0){
            setSelectedModel(his[0]);
        }
    }
    // console.log(selected_model);
    const handleOpenEditDialog = () =>{
        setOpenEditDialog(true);
    }
    const handleCloseEditDialog = () =>{
        setOpenEditDialog(false);
    }

    const handleOpenResumeTrainingDialog = () =>{
        setOpenResumeTrainingDialog(true);
    }

    const handleDeleteModel = () =>{
        ProjectService.deleteModel(model_id).then(res=>{
            // console.log(res.data)
            history(`/project/${model.project}`)
        }).catch(err =>{
            console.log(err)
        })
    }
    const handleClickOpenDeleteModal = (proj) => {
        setOpenDeleteModal(true);
    };

    const handleDeleteModelClose = () => {
        setOpenDeleteModal(false);
    };
    if(!isLoading && model===null){
        // console.log("error")
        return <NotFoundPage/>
    }

    return (

        <>
            {
                isLoading?
                <div style={{paddingTop:"2cm"}} className='d-flex min-vh-100 justify-content-center align-items-center' >
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                : <div style={{marginTop:"2cm"}} className="pt-3 px-3">        
                    <div className='d-flex model-testing-heading'>
                        <HomeIcon/>
                        <span className='ms-2'>
                            <Link to={`/project/${model?.project}`}>{proj_name}</Link> / {model?.name}
                        </span>
                    </div>                
                    <div className='bg-white p-4 rounded shadow-sm' >
                    <div className='d-flex flex-row justify-content-between mb-3'>
                        <h5 className='fw-bold text-dark p-2 px-4 rounded bg-primary bg-opacity-25'>{model?.name}</h5>
                        <div className='pt-2 d-flex'>
                            {/* <Button component={Link} to={`/label/${proj_name}/${model_name}`} style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} className="mx-1">
                                Draw Annotations
                            </Button> */}
                            {/* <Button onClick={handleClickOpen} style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} className="mx-1">
                                Train
                            </Button> */}
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClickMenu}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    
                                    open={menuOpen}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={handleOpenEditDialog} className="model_details_page_menu_item">
                                        Edit Details
                                    </MenuItem>
                                    <MenuItem component={Link} to={`/label/${model_id}`}  className="model_details_page_menu_item">
                                        Draw Annotations
                                    </MenuItem>
                                    {   model.training_attempted===true && model?.status !== "completed" ?
                                        <MenuItem onClick={handleOpenResumeTrainingDialog} className="model_details_page_menu_item">
                                            Resume Training
                                        </MenuItem>:null
                                    }
                                    <MenuItem onClick={handleClickOpen} className="model_details_page_menu_item">
                                        Train
                                    </MenuItem>
                                    
                                    <MenuItem onClick={handleClickOpenDeleteModal} className="model_details_page_menu_item">
                                        Delete Model
                                    </MenuItem>
                                    <EditModelDialog openEditDialog={openEditDialog} handleCloseEditDialog={handleCloseEditDialog} setIsModelUpdated ={setIsModelUpdated} />
                                </Menu>
                            </div>

                            <TrainingOptionsDialog 
                                open={open} 
                                handleClose={handleClose} 
                                getModelDetails={getModelDetails} 
                                // setSelectedModel={setSelectedModel} 
                            />
                            <ResumeTrainingDialog  
                                openResumeTrainingDialog={openResumeTrainingDialog} 
                                setOpenResumeTrainingDialog={setOpenResumeTrainingDialog} 
                                model_id = {model._id} 
                                // model_version_id = {selected_model} 
                            />
                        
                        </div>
                    </div>
                    <div className='d-flex w-75 align-items-center'>
                        <div className='fw-bold'>
                            Labels: 
                        </div>
                        <div className='d-flex flex-wrap'>
                            {labels?.map((m,i)=>
                                <div 
                                    key={i}
                                    style={{'maxWidth':'max-content'}} 
                                    className='p-1 bg-primary text-white rounded m-1 shadow-sm' 
                                    data-bs-toggle="tooltip" 
                                    data-bs-placement="bottom" 
                                    title="Labels"
                                >
                                {m} 
                                </div>
                            )}
                        </div>
                        
                    </div>
                </div>
                <ModelTrainingMetrics 
                    model = {model} 
                    url={url} 
                    handleOpenResumeTrainingDialog = {handleOpenResumeTrainingDialog}
                    model_id = {model_id}
                    // selected_model={selected_model} 
                    // setSelectedModel={setSelectedModel} 
                />
                {
                    model?.status==='completed' && model?.metrics!==null ?<>
                        <ModelSummary 
                            metrics = {model.metrics} 
                            selected_model={selected_model} 
                            // setSelectedModel={setSelectedModel} 
                        />
                    </>
                    :null
                }
                {/* <TrainedModels trained_models={model?.trained_models} url={url} /> */}
                {
                    model?.status==='completed'?<APIDetails 
                        // selected_model={selected_model} 
                        // setSelectedModel={setSelectedModel}
                        />:null
                }
                
                <ModelFiles model_id = {model._id} project_id = {model.project} />

                <Dialog
                    open={openDeleteModal}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle><span className="fs-6">{"Confirm to delete this ?"}</span></DialogTitle>
                    <DialogContent>
                        <div style={{minWidth:'20vw',minHeight:'5vh'}}></div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDeleteModelClose} color="secondary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={(e)=> {
                            handleDeleteModel();
                        }} 
                        color="primary" autoFocus
                    >
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
            }
        </>
    )
}

export default ModelDetailsPage
