import React, { useEffect, useState } from 'react'
import { FilePond } from 'react-filepond';
import { Link, useParams } from 'react-router-dom'
import  Button  from 'components/CustomButtons/Button.js';
import OCRInput from './OCRInput';
import ProcessingForOCR from './ProcessingForOCR';
import OCRResults from './OCRResults';
import HomeIcon from '@material-ui/icons/Home';
import  ProjectService  from '../../../services/project.service';

function OCRPage() {
    const {model_id} = useParams();

    const [model,setModel] = useState("");
    const [files, setFiles] = useState([])
    const [loading,setLoading] = useState(false)
    const [ocrState, setOcrState] = useState(0);
    const [ocrResults,setOcrResults] = useState([]);

    useEffect(()=>{
        ProjectService.getSingleModel({model_id}).then(res => {
            setModel(res.data);
        })
    },[])

    return (
        <>
            {
                loading? <div style={{paddingTop:"2cm"}} className='d-flex min-vh-100 justify-content-center align-items-center' >
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                </div> : <div style={{marginTop:"2cm"}} className="pt-3 px-5 mx-5">
                    <div className='d-flex model-testing-heading'>
                        <HomeIcon/>
                        <span className='ms-2'>
                            <Link to={`/project/${model?.project}`}>{model?.proj_name}</Link> / <Link to={`/model/${model?._id}`}>
                                {model?.name}
                            </Link> / OCR
                        </span>
                    </div>
                    {/* <h3 className='model-testing-heading'><Link to={`/project/${proj_name}`}>{proj_name}</Link>  / <Link to={`/model/${proj_name}/${model_name}`}>{model_name}</Link> / OCR</h3> */}
                    <div className='bg-white p-4 rounded shadow-sm'>
                    {
                        ocrState===0?<OCRInput setOcrState = {setOcrState} setOcrResults={setOcrResults} />: ocrState===1?<ProcessingForOCR/>:<OCRResults ocrResults={ocrResults}/>
                    }
                    </div>
                </div>
            }
        </>
    )
}

export default OCRPage