import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControl from '@material-ui/core/FormControl';
import  Button  from 'components/CustomButtons/Button.js';
import Select from '@material-ui/core/Select';
import { useState } from 'react';
import  Input  from '@material-ui/core/Input';
import  InputLabel  from '@material-ui/core/InputLabel';
import { useParams, useNavigate } from 'react-router-dom';
import ProjectService from "../../services/project.service"
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, Slider, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[5],
    width:"20%",
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    textAlign:'center'
  },
}));

export default function TrainingOptionsDialog({open,handleClose}) {
  const classes = useStyles();
  const {model_id} = useParams();

  const history = useNavigate();
  const [weight,setWeight] = useState("yolov5m");
  const [epochs,setEpochs] = useState(300);
  const [batchSize,setBatchSize] = useState(2);
  const [showModal,setShowModal] = useState(false);
  const [loading,setLoading] = useState(false);
  const [trainTestProportion,setTrainTestProportion] = useState(80);
  // const [train_from_scratch, setTrainFromScratch] = useState(true);

  const weightsArray = ["scratch", "yolov5n","yolov5s","yolov5m", "yolov5l", "yolov5x"];
  const batchSizeArray = [1,2,4,8,16,32,64];

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  }
  const handleSubmit = () => {
    if(weight === "scratch"){
      weight="";
    }
    setLoading(true);
    const promise = toast.promise(ProjectService.trainModel({model_id,weight,epochs,batchSize,trainTestProportion}),
                          {
                            'pending':'Training...',
                            'success':'Training Completed',
                            'error':'Training Failed'
                          } ,
                          {
                            autoClose: false,
                          }    
    )
    history(`/model/${model_id}`);
    promise.then(
      (response) => {
        handleClose();
        setLoading(false);
      }
    ).catch(error=>{
      console.log(error);
      // alert("Error occured while training. Please try again.")
    });
    
  }


  const handleTrain = () => {
    handleSubmit();
    handleClose(); 
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Choose training options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>

          {/* <FormControlLabel
            control={<Checkbox checked={train_from_scratch} onChange={(e)=> setTrainFromScratch(e.target.checked)} name="train_from_scratch" />}
            label="Test from scratch"
          /> */}

          <Typography id="discrete-slider" gutterBottom className='mt-3'>
            Train-Test proportion
          </Typography>
          <Slider
            defaultValue={80}
            // getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={10}
            value={trainTestProportion}
            marks
            onChange={(event, newValue) => {setTrainTestProportion(newValue)}}  
            min={10}
            max={100}
          />
          <InputLabel id="weights">Weights</InputLabel>
          <Select
            labelId="weights"
            id="weights"
            value={weight}
            onChange={(e)=>setWeight(e.target.value)}
            input={<Input />}
            fullWidth
            className="mb-3"
              // MenuProps={MenuProps}
          >
            {weightsArray.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Epochs"
            value = {epochs}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange = {(e)=>setEpochs(e.target.value)}
            className="mb-3"
            type="text"
            fullWidth
          />
          <InputLabel id="batch-size">Batch Size</InputLabel>
          <Select
            labelId="batch-size"
            id="batch-size"
            value={batchSize}
            onChange={(e)=>setBatchSize(e.target.value)}
            input={<Input />}
            className="mb-3"
              // MenuProps={MenuProps}
          >
            {batchSizeArray.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button onClick={handleTrain} style={{backgroundImage:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,170,170,1) 0%, rgba(191,181,191,1) 0%, rgba(255,97,143,1) 0%, rgba(140,47,255,1) 0%, rgba(129,60,250,1) 0%, rgba(66,102,255,1) 100%, rgba(30,255,248,1) 100%)"}}>
            Train
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open = { showModal }
        className={classes.modal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            {
              loading? <>
                  <CircularProgress disableShrink />
                  <b className='mt-2'>Processing...</b>
              </>:<>
                <Box
                  color={'green'}
                  width={'40px'}
                  height={'40px'}
                  component={CheckCircleIcon}
                />
                <h5><b>{loading?"":"Training Started"}</b></h5>
              </>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
