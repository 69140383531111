import React, { useState } from 'react';
import { Link, Route, Routes, useLocation, useParams,useMatch  } from 'react-router-dom';
import UploadFiles from './../../UploadImage/UploadFiles';
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import '../../UploadImage/upl.css'
import  Button  from 'components/CustomButtons/Button.js';
import ProjectService from "../../../services/project.service";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HomeIcon from '@material-ui/icons/Home';
import ModelTestInput from './ModelTestInput';
import TestResultsPage from './TestResultsPage';
import { useEffect } from 'react';

const ModelTestingPage = () => {
    const {model_id} = useParams();
    // const {proj_name} = useLocation();
    // const { path, url } = useMatch ();

    const [isLoading,setLoading] = useState(false);
    const [state,setState] = useState(0);
    const [model,setModel] = useState("");
    const [results,setResults] = useState([]);


    useEffect(()=>{
        ProjectService.getSingleModel({model_id}).then(res => {
            setModel(res.data);
        })
    },[])
    console.log(model?.proj_name)
    return <div>
        {
                isLoading?
                <div style={{paddingTop:"2cm"}} className='d-flex min-vh-100 justify-content-center align-items-center' >
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                : <div style={{marginTop:"2cm"}} className="pt-3 px-3">
                    <div className='d-flex model-testing-heading'>
                        <HomeIcon/>
                        <span className='ms-2'>
                            <Link to={`/project/${model?.project}`}>{model?.proj_name}</Link> / <Link to={`/model/${model?._id}`}>
                                {model?.name}
                            </Link> / test
                        </span>
                    </div>
                    <div className='bg-white p-4 rounded shadow-sm'>
                        {state === 0?
                            <ModelTestInput 
                                setState={setState} 
                                setResults={setResults} 
                            />:state===1?<>
                                <span className='h5'>Test your model</span>
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className='ms-3'>Processing...</div>
                                </div>
                            </>:<TestResultsPage results={results} state={state} />
                        }
                                
                    {/* {
                        state === 0?<ModelTestInput projName={proj_name} modelName = {model_name} setState={setState} setResults={setResults} />: 
                            state === 1?<>
                                    <span className='h5'>Test your model</span>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className='ms-3'>Processing...</div>
                                    </div>
                                </>:<TestResultsPage results={results}/>
                    } */}
                    </div>
                </div>
        }
    </div>;
};

export default ModelTestingPage;
