import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import  Button  from 'components/CustomButtons/Button.js';
import  ProjectService  from '../../services/project.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControl, FormHelperText, FormLabel, Input } from '@material-ui/core';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditModelDialog({openEditDialog, handleCloseEditDialog,setIsModelUpdated}) {

  const {model_id} = useParams();
  const [model,setModel] = useState(null)
  const [model_name,setModelName] = useState('');
  const [labels, setLabels] = useState("")
  const [updating, setUpdating ] = useState(false);
  const [showError,setShowError] = useState(false)
  const [isValidName, setIsValidName] = useState(null);
  
  useEffect(()=>{
        function getModelDetails(){
            ProjectService.getSingleModel({model_id}).then(res=>{
                setModel(res.data)
                setModelName(res.data.name)
                const obj= JSON.parse(res.data.labels)
                setLabels(obj.toString())
                // return ress
            }).
            catch(error=>{
                console.log(error?.response?.status)
                if(error?.response?.status===404){
                    // console.log("in error")
                    return error;
                }
            })
        }
        getModelDetails();

  },[])

  const onChangeLabel = (e) => {
    // console.log(e.target.value)
    const labels = e.target.value;
    setLabels(labels);
  };

  const onChangeModelName = (e) => {
    // console.log(e.target.value)
    setShowError(false);
    console.log(model_name);
    const modelName = e.target.value;
    setModelName(modelName);
  };

  const handleUpdate = () => {
    setUpdating(true);
    console.log(labels)
    var labs=labels.split(',')
    var labls= labs.map(function (el) {
      return el.trim();
    })

    const formData = new FormData();
    formData.set('_id',model_id);
    formData.set('name',model_name);
    formData.set('proj_id',model?.project);
    formData.set('labels',JSON.stringify(labls))
    ProjectService.updateProject('model',formData).then(res=>{
      setUpdating(false);
      console.log(res);
      toast.success("Model details updated",{theme: "colored",autoClose: 15000})
      handleCloseEditDialog();
      setIsModelUpdated(true);
    //   alert("Model Updated Successfully")
    }).catch(error=>{
        setUpdating(false);
        setShowError(true);
        setIsValidName(false);
        console.log(error)
    })
  }
  
  return (
    <div>
      <Dialog 
        // onClose={handleCloseEditDialog} 
        aria-labelledby="customized-dialog-title" 
        open={openEditDialog}
        className='w-100'
      >
        <DialogTitle 
          id="customized-dialog-title" 
          onClose={handleCloseEditDialog}
        >
          <span className='h6 text-capitalize' >Edit model details</span>
        </DialogTitle>
        <DialogContent dividers >
          {
            updating?<div style={{minWidth:'40vw',minHeight:'20vh'}} className='d-flex justify-content-center align-items-center'> 
                <CircularProgress/> 
                <span className='mx-2'>Updating...</span>
              </div>: <div style={{minWidth:'40vw',minHeight:'20vh'}} className='d-flex flex-column justify-content-center align-items-center'>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
              >
                  <FormLabel className='text-dark'>Model name</FormLabel>
                  <Input
                      placeholder="Enter model name"
                      name="model_name"
                      defaultValue = {model_name}
                    //   value={model_name}
                      onChange={(e) => onChangeModelName(e)}
                    //   component={Input}
                      autoComplete="off"
                      type="text"
                  />
                  <FormHelperText >
                    {   showError?isValidName?<span className='text-success'>
                          *Name Available
                        </span>:
                        <span className='text-danger'>
                          *Model with this name already exists</span>
                          :null
                    }
                  </FormHelperText>
              </FormControl>
              <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
              >
                  <FormLabel className='text-dark'>Labels</FormLabel>
                  <Box
                      className=""
                      defaultValue = {labels}
                    //   value={labels}  
                      placeholder="Enter labels (comma separated)"
                      name = "labels"
                      onChange ={ (e) =>onChangeLabel(e)}
                      component = {Input}
                      autoComplete = "off"
                      type="text"
                  />
                  <FormHelperText >*Enter comma separated values</FormHelperText>
              </FormControl>
            </div>
          }
         
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus
            disabled={model_name==="" || labels==="" || (showError && !isValidName)} 
            onClick={(e)=> {
              handleUpdate()  
            }
            }
            style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
