import React, { useState } from 'react'
import { FilePond } from 'react-filepond';
import { Link, useParams } from 'react-router-dom'
import  Button  from 'components/CustomButtons/Button.js';
import ProjectService from '../../../services/project.service';
import {FormControl, FormControlLabel, FormHelperText, Input } from '@material-ui/core';

const OCRInput = ({setOcrState,setOcrResults}) => {
    const {model_id,slug} = useParams();

    const [files, setFiles] = useState([])
    const [config,setConfig] = useState("")

    const handleUpload = (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.set('config',config);
        files.forEach(file => formData.append('files', file.file))

        // for (var pair of formData.entries()) {
        //   console.log(pair[0]+ ', ' + pair[1]); 
        // }
        setOcrState(1);
        console.log(formData);
        ProjectService.getOCRData(model_id,slug,formData)
        .then((res) => {
            setOcrState(2);
            console.log("OCR Data")
            console.log(res)
            setOcrResults(res.data)
        }).catch(error => {
            setOcrState(0)
            alert("Error while processing for OCR")
            console.log(error)
        })
    }
    return (
        <>
            <div className = 'py-3 h5'>Upload Images for OCR</div>
            <div>
                <FormControl className='d-flex flex-row mx-3 my-4 align-items-center'>
                    <div className='p-2'> Custom config <span className='text-secondary'>(optional)</span> : </div>
                    <Input onChange={(e) => setConfig(e.target.value) } value={config} className='flex-grow-1'/>
                </FormControl>

            </div>
            <div style={{"border":"2px dashed gray"}} className="m-3 bg-white rounded fs-5 p-3 shadow-sm">
                <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    // maxFiles={100}
                    server="/api"
                    name="files"
                    instantUpload = "false"
                    labelIdle='<div>Drag & Drop your files or <div class="fs-5 text-primary filepond--label-action">Choose Your files</div></div>'
                    labelButtonProcessItem="Start Upload"
                    credits='false'
                    styleItemPanelAspectRatio='1'
                />
            </div>
            <div className="text-center">
                <Button disabled={!files.length>0} onClick={handleUpload} style = {{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} >
                    Process
                </Button>
            </div>
        </>
    )
}

export default OCRInput