import React from 'react'
import { useEffect, useState } from 'react';
import ProjectService from "../../services/project.service"
import { useParams, Link } from 'react-router-dom';
import { 
    Box, 
    ListItemSecondaryAction, 
    IconButton,
    Button, 
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload  from '@material-ui/icons/CloudDownload';
import ImageIcon from '@material-ui/icons/Image';
import FolderIcon from '@material-ui/icons/Folder';
// import Button  from 'components/CustomButtons/Button.js';
import PublishIcon from '@material-ui/icons/Publish';
import UpdateModelFilesDialog from './Helper/UpdateModelFilesDialog';


const ModelFiles = ({model_id,project_id}) => {
    const [modelFiles,setModelFiles] = useState([]);
    const [model_name,setModelName] = useState("");
    const [loading, setLoading] = useState(true);
    const [showFiles,setShowFiles] = useState(false);
    const [openUpdateFilesDialog, setOpenUpdateFilesDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenUpdateFilesDialog(true);
    };
    const handleClose = () => {
        setOpenUpdateFilesDialog(false);
    };
    var fileDownload = require('js-file-download');

    useEffect(()=>{
        ProjectService.getImages({model_id}).then(
            (res)=>{
                // console.log(res.data);
                let tempData = [];
                for (let image of res.data.images){
                    const data = image;
                    // console.log(image)
                    data['bounding_box'] = JSON.parse(image["bounding_box"]);
                    tempData.push(data)
                }
                setModelName(res.data.model_name);
                setModelFiles(tempData);
                setLoading(false);
            }
        ).catch(error=>{
            console.log(error);
            setLoading(false)
        })
    },[])


    const handleDownload = (e,box_id,filename) =>{
        ProjectService.downloadAnnotation({box_id}).then(res=>{
            // console.log(res.data)
            fileDownload(JSON.stringify(res.data), filename.split('.')[0]+".json");
        })
    }
    // console.log(modelFiles)
    return (
        <div className='my-4 p-4 bg-white shadow-sm rounded'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='h6'>Files</div>
                <div>
                    <Button
                        onClick={handleClickOpen}
                        startIcon={<PublishIcon/> } 
                        style={{"backgroundColor":"rgb(110, 110, 254)","color":"white","textTransform":"capitalize"}} 
                    >
                        Upload Files
                    </Button>
                </div>
            </div>
            <div>
                {
                    loading?
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> 
                    :
                    showFiles===false?
                    <div onClick={(e)=> setShowFiles(true)} className="model_files_folder"><Box component={FolderIcon} width={"5rem !important"} height={"5rem !important"} /><div className='text-dark text-center'>{model_name}</div></div>:
                    <List>
                        <Button onClick={(e)=>setShowFiles(false)} className='mb-3' style={{"backgroundColor":"rgb(110, 110, 254)","color":"white","textTransform":"capitalize"}}>Hide Files</Button>
                    {
                        modelFiles.length > 0 ? modelFiles.map(file=>
                                <ListItem key={file.box_id} alignItems='flex-start' className='model-file-details'>
                                    {/* <ListItemIcon className="text-white">
                                        <ImageIcon/>
                                    </ListItemIcon> */}
                                    <ListItemText
                                        primary={file.file_name}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={(e)=>handleDownload(e,file.box_id,file.file_name)} disabled={!file.bounding_box.length > 0} edge="end" aria-label="CloudDownloads">
                                            <CloudDownload />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                        ) : <div className='d-flex justify-content-center align-items-center'>
                            <span className='bg-danger bg-opacity-10 p-2 rounded'>No Files</span>
                        </div>
                    }
                    </List>
                }

                <UpdateModelFilesDialog open={openUpdateFilesDialog} handleClickOpen={handleClickOpen} handleClose={handleClose} model_id={model_id} project_id={project_id} />
            </div>
        </div>
    )
}

export default ModelFiles
