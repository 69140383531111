import React from 'react'
import '../ModelsPage.css'
import  Button  from 'components/CustomButtons/Button.js';
import DownloadOCRDropdown from './DownloadOCRDropdown';
import {VscJson} from 'react-icons/vsc'
function OCRResults({ocrResults}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenDownloadDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDownloadDropdown = () => {
        setAnchorEl(null);
    };

    console.log(ocrResults);
    const domain = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/":"http://127.0.0.1:8000/";

    return (
        <div>
            <div className='h5'><i className='fa-solid fa-file-csv'></i>OCR Results</div>
            <div className='d-flex justify-content-end'>
                <Button 
                    onClick={handleOpenDownloadDropdown}
                    style = {{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}
                >
                  Download
                </Button>
                
                <DownloadOCRDropdown data={ocrResults} anchorEl={anchorEl} handleCloseDownloadDropdown={handleCloseDownloadDropdown} />
            
            </div>
            {
                ocrResults?.map((ocrResult,index)=><div key={index} className='ocr_results__item'>
                    <img src={`${domain}${ocrResult.url}`} alt='ocr_image' className='border border-2' style={{width:"40%",height:"40%"}}/>
                    <div className='ps-4'>
                        {
                            Object.keys(ocrResult.text)?.map((key,index)=><div key={index} className='ocr_results_text'><b>{key}</b> : {ocrResult.text[key]}</div>)
                        }
                    </div>
                </div>)
            }
        </div>
    )
}

export default OCRResults