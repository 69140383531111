import React, { useState } from 'react';
import { Link, useParams,useNavigate,useMatch  } from 'react-router-dom';
import UploadFiles from './../../UploadImage/UploadFiles';
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import '../../UploadImage/upl.css'
import  Button  from 'components/CustomButtons/Button.js';
import ProjectService from "../../../services/project.service";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { IconButton,Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { toast } from 'react-toastify';

const ModelTestInput = ({modelName,setState,setResults}) => {
    const history = useNavigate();
    // const { path, url } = useMatch ();
    const {model_id,slug} = useParams();
    const domain = process.env.NODE_ENV==='production'?"https://demo.docextractor.com/":"http://127.0.0.1:8000/";

    const [files, setFiles] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuOpen = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleTestWithExistingFiles = () => {
        setState(1);
        ProjectService.testModelWithExistingFiles({model_id}).then(res => {
            // console.log(res.data)
            const {data} =res;
            let resultsArray = [];
            for(let i=0;i<data.imageUrls.length;i++){
                resultsArray.push(domain+data.imageUrls[i]);
            }
            setResults(resultsArray)
            setState(2)
        }).catch(err => {   
            console.log(err?.response);
            console.log(err)
            setState(0)
            toast.error(err?.response?.data?.message || "Something went wrong")
        })
    }
    const handleClickDownload = () => {
        ProjectService.downloadModel({model_id}).then(res => {

            // const url = window.URL.createObjectURL(new Blob([res.data],{type:'application/zip'}));
            const link = document.createElement('a');
            // const domain = "http://localhost:8000/"
            link.href = domain+res.data.file_path;
            // link.href = url;
            // link.setAttribute('download', 'file.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(err => {
            console.log(err)
        })
    }
    const uploadAndTest = () =>{
        const formData = new FormData();
        formData.set('model_id', model_id)
        formData.set('slug',slug)
        files.forEach(file => formData.append('files', file.file))
        setState(1)
        ProjectService.testModelWithNewFiles(formData).then(res => {
            // console.log(res.data)
            const {data} =res;
            let results = [];
            for(let i=0;i<data.imageUrls.length;i++){
                results.push(domain+data.imageUrls[i]);
            }
            setResults(results)
            setState(2);

        }).catch(err => {
            console.log(err)
            setState(0)
        })
    }
    return <>
            <div className='d-flex justify-content-between'>
                <span className='h5'>Test your model</span>
                <div>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClickMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        
                        open={menuOpen}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleClickDownload} className="model_details_page_menu_item">
                            Download Model
                        </MenuItem>
                        <MenuItem onClick={handleTestWithExistingFiles} className="model_details_page_menu_item">
                            Test With existing files
                        </MenuItem>
                        <MenuItem component={Link} to={`/model/${model_id}/ocr`} className="model_details_page_menu_item">
                            Process for OCR
                        </MenuItem>
                    </Menu>
                </div>
                {/* <Button 
                    component={Link} 
                    to={`/model/${model_id}/ocr`} 
                    variant="contained"
                    style={{backgroundImage: "linear-gradient(45deg, rgb(100 134 255), rgb(0 210 78))"}}
                >
                        Process for OCR
                </Button> */}
            </div>
            
            <div className='mt-3'>
                {/* <div className='align-items-center'>
                    <span className='me-3'>Download model to test locally</span>
                    <Button startIcon={<CloudDownloadIcon/>} onClick={handleClickDownload} style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}>
                        Download
                    </Button>
                </div>
                <div className='h6 mt-3'>OR</div>
                <div className='align-items-center'>
                    <span className='me-4'>Test with existing files</span>
                    <Button  onClick={handleTestWithExistingFiles} style={{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}}>
                        Test
                    </Button>
                </div>
                <div className = 'h6 mt-3'>OR</div> */}
                <div className = 'py-3 h6'>Upload images to test the model</div>
                <div style={{"border":"2px dashed gray"}} className="m-3 bg-white rounded fs-5 p-3 shadow-sm">
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={true}
                        // maxFiles={100}
                        server="/api"
                        name="files"
                        instantUpload = "false"
                        labelIdle='<div>Drag & Drop your files or <div class="fs-5 text-primary filepond--label-action">Choose Your files</div></div>'
                        labelButtonProcessItem="Start Upload"
                        credits='false'
                        styleItemPanelAspectRatio='1'
                    />
                </div>
                <div className="text-center">
                    <Button disabled={!files.length>0} onClick={uploadAndTest} style = {{backgroundImage:"linear-gradient(90deg, rgb(129, 60, 250) 0%, rgb(66, 102, 255) 100%)"}} >
                        Upload & Test
                    </Button>
                </div>
            </div>
        </>
};

export default ModelTestInput;
