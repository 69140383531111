import React, {useState, useCallback,useEffect,} from 'react'
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'
import { useDispatch, useSelector } from "react-redux";
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './upl.css'
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)


// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Button from 'components/CustomButtons/Button.js';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { createProject } from '../../actions/project'


const useStyles = makeStyles(styles);

export default function UploadPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [files, setFiles] = useState([])
  const [proj_name, setProjName] = useState([])
  const [labels, setLabels] = useState([])
  const dispatch = useDispatch();
  const onChangeLabel = (e) => {
    const labels = e.target.value;
    setLabels(labels);
  };

  const onChangeProjName = (e) => {
    const ProjName = e.target.value;
    setProjName(ProjName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var labs=labels.split(',')
    var labls= labs.map(function (el) {
      return el.trim();
    })
    const formData = new FormData();
    formData.set('proj_name', proj_name)
    files.forEach(file => formData.append('images', file.file))
    formData.append('labels', JSON.stringify(labls))
    formData.append('bounding_box', JSON.stringify([]))
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }
  
    dispatch(createProject( formData))
      .then(() => {
        props.history.push("/label/"+proj_name);
        window.location.reload();
      });
    
  };

  return (
    <div>
     
      <Parallax
        small
        filter
        // image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}></GridItem>
            </GridContainer>
            
            <GridContainer justify="center">
              <GridItem className={classes.navWrapper}>
              <div className="App">
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  // maxFiles={100}
                  server="/api"
                  name="files"
                  instantUpload = "false"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  labelButtonProcessItem="Start Upload"
                  credits='false'
                  styleItemPanelAspectRatio='1'
                />
              </div>

              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                    id="regular"

                    inputProps={{
                        onChange: (e) => onChangeProjName(e),
                        placeholder: "Enter Enter project name"
                    }}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
            </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                    id="regular"
                    inputProps={{
                        onChange: (e) => onChangeLabel(e),
                        placeholder: "Enter labels, comma separated"
                    }}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
            </GridItem>

            <Button onClick={handleSubmit} color="primary">Submit</Button>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
